import React from "react";
import styled from "styled-components";
// import facebook from "../../assets/svgs/facebook.svg";
import threads from "../../assets/svgs/threads.svg";
import instagram from "../../assets/svgs/instagram.svg";
import x from "../../assets/svgs/x.svg";
import youtube from "../../assets/svgs/youtube.svg";
import mixlr from "../../assets/svgs/mixlr.svg";
import { Text } from "../../components";

export const Socials = () => {
  return (
    <Wrapper>
      <Text level={4}>Connect with us on our social media platforms</Text>
      <div className="grid grid-cols-2 gap-8 pt-4 pb-4">
        {/* <div className="flex items-center gap-4">
          <img width="50px" src={facebook} alt="facebook" />
          <Text type="p">The covenant template lekki</Text>
        </div> */}
        <div className="flex items-center gap-4">
          <img width="50px" src={instagram} alt="instagram" />
          <Text type="p">TCN Lekki</Text>
        </div>
        <div className="flex items-center gap-4">
          <img width="50px" src={x} alt="x (formally twitter)" />
          <Text type="p">@TCN Lekki</Text>
        </div>
        <div className="flex items-center gap-4">
          <img width="50px" src={threads} alt="threads" />
          <Text type="p">TCN Lekki</Text>
        </div>
      </div>

      <Text level={4}>
        You can also listen to our messages on our Youtube channel or via Mixlr
      </Text>
      <div className="grid grid-cols-2 gap-8 pt-4 pb-4">
        <div className="flex flex-col gap-4">
          <img width="150px" src={youtube} alt="facebook" />
          <Text type="p">The covenant template lekki (TCN Lekki)</Text>
        </div>
        <div className="flex flex-col gap-4">
          <img width="150px" src={mixlr} alt="facebook" />
          <Text type="p">TCN Lekki</Text>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
