import React from "react";
import { GivingIntro } from "../../components/organisms/GivingIntro";
import styled from "styled-components";
import gtbLogo from "../../assets/bank_icons/gtco.png";
import stanbicLogo from "../../assets/bank_icons/stanbic.png";
import zenithLogo from "../../assets/bank_icons/zenith.png";
import wemaLogo from "../../assets/bank_icons/wema.jpeg";
import { GivingDetailsContainer } from "../../components/organisms/GivingDetailsContainer";

export const DashboardGive = () => {
  return (
    <Wrapper>
      <GivingIntro marginTop="0.5rem" />
      <GivingDetailsContainer
        title="TCN Global"
        background="#FFF6EB"
        color="black"
        combined={false}
        localAccounts={[
          {
            logo: gtbLogo,
            bankName: "GUARANTY TRUST BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0010471264",
          },
          {
            logo: zenithLogo,
            bankName: "ZENITH BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "1010845720",
          },
          {
            logo: stanbicLogo,
            bankName: "STANBIC IBTC BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0003699923",
          },
        ]}
        textAlign="left"
        cardTextAlign="left"
        cardItemsAlign="flex-start"
      />
      <GivingDetailsContainer
        background="#FFFFFF"
        subTitle="Domicilary Accounts: In order to give while abroad or in foreign
        currency, please utilize the details below"
        color="black"
        localAccounts={[
          {
            logo: gtbLogo,
            bankName: "GUARANTY TRUST BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0114030385",
          },
          {
            logo: stanbicLogo,
            bankName: "STANBIC IBTC BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0036649009",
          },
        ]}
        combined={false}
        textAlign="left"
        cardTextAlign="left"
        cardItemsAlign="flex-start"
      />
      <GivingDetailsContainer
        title="TCN Local"
        background="#FFF6EB"
        color="black"
        combined={false}
        localAccounts={[
          {
            logo: gtbLogo,
            bankName: "GUARANTY TRUST BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0010471264",
          },
          {
            logo: zenithLogo,
            bankName: "ZENITH BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "1010845720",
          },
          {
            logo: stanbicLogo,
            bankName: "STANBIC IBTC BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0003699923",
          },
          {
            logo: wemaLogo,
            bankName: "WEMA BANK",
            accountName: "TCN LEKKI WELFARE",
            accountNumber: "8270014404",
          },
          {
            logo: wemaLogo,
            bankName: "WEMA BANK",
            accountName: "TCN LEKKI PROJECTS",
            accountNumber: "8270014413",
          },
        ]}
        textAlign="left"
        cardTextAlign="left"
        cardItemsAlign="flex-start"
      />
      <GivingDetailsContainer
        background="#FFFFFF"
        subTitle="Domicilary Accounts: In order to give while abroad or in foreign
        currency, please utilize the details below"
        color="black"
        localAccounts={[
          {
            logo: gtbLogo,
            bankName: "GUARANTY TRUST BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0114030385",
          },
          {
            logo: stanbicLogo,
            bankName: "STANBIC IBTC BANK",
            accountName:
              "Covenant Christian World Assembly (Covenant Christian Centre)",
            accountNumber: "0036649009",
          },
        ]}
        combined={false}
        textAlign="left"
        cardTextAlign="left"
        cardItemsAlign="flex-start"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;
