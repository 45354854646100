import React, { useState } from "react";
import { PageTemplate } from "../../templates/PageTemplate";
import styled, { css } from "styled-components";
import { Button, Input } from "../../components";
import aboutHero from "../../assets/images/static_assets/about_hero.jpg";
import sundaySchool from "../../assets/images/sunday-school.png";
import prayer from "../../assets/images/prayer.png";
import feedback from "../../assets/images/feedback.png";
import midweek from "../../assets/images/midweek.png";
import { Container } from "../../components/atoms/Container";
import { screen } from "../../theme";
import { Form } from "antd";
import { toast } from "react-toastify";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
// import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../api/axios";

export const About = () => {
  const [feedbackInfo, setFeedbackInfo] = useState({});
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/feedback", feedbackInfo);

      if (response.data) {
        form.resetFields();
        toast.success("Feedback sent successfully!");
      }
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };

  return (
    <PageTemplate>
      <Hero>
        <div className="bg-filter">
          <HeroHeading>Welcome to The Covenant Nation - Lekki</HeroHeading>
        </div>
      </Hero>

      <GetInvolved background="#fff">
        <Container className="flex flex-col md:flex-row gap-12 items-center">
          <div className="flex flex-column gap-4 w-full md:w-2/4">
            <p className="invite-text">
              Experience total transformation in every residentialArea of your
              life
            </p>
          </div>
          <div className="flex flex-column gap-4 w-full md:w-2/4">
            <p>
              Experience total transformation in every residentialArea of your
              life through the spirit filled teachings of Pastor Poju Oyemade
              and all the anointed ministers of The Covenant Nation Lekki. We
              currently hold two services on Sunday as follow:
            </p>

            <div>
              <p>1st Service: 7:45am - 9:30am</p>
              <p>2nd Service: 10:00am - 12:00 noon</p>
            </div>

            <p>
              We hold our Mid-week service every Thursday from 6:30pm to 8pm
            </p>
          </div>
        </Container>
      </GetInvolved>

      <Container className="flex flex-col md:flex-row justify-center">
        <img src={sundaySchool} alt="Sunday school" />
        <img src={prayer} alt="prayer" />
        <img src={midweek} alt="midweek" />
      </Container>

      <GetInvolved className="mt-24">
        <Container className="flex flex-col md:flex-row gap-12 items-center">
          <div className="flex flex-column gap-4 w-full md:w-4/6 pr-0 md:pr-16">
            <p className="invite-text">Feedback</p>
            <p className="invite-sub-text">
              We love to hear from you. Share your experience(s) and concern(s)
              and we will do our possible best to provide an excellent worship
              environment
            </p>
            <div className="w-full flex-col gap-4">
              <Form
                form={form}
                name="register"
                onFinish={handleSubmit}
                scrollToFirstError
              >
                <div className="flex flex-col">
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Your name"
                      value={feedbackInfo.name}
                      onChange={(e) =>
                        setFeedbackInfo((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      label="Name"
                      id="name"
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Enter email"
                      value={feedbackInfo.email}
                      onChange={(e) =>
                        setFeedbackInfo((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                      label="Email"
                      id="email"
                      prefix={<MailOutlined />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "This field is requred!",
                      },
                    ]}
                  >
                    <Input
                      type="textarea"
                      placeholder="Type message"
                      value={feedbackInfo.message}
                      onChange={(e) =>
                        setFeedbackInfo((prev) => ({
                          ...prev,
                          message: e.target.value,
                        }))
                      }
                      label="Message"
                      id="message"
                    />
                  </Form.Item>
                </div>
                <div className="w-1/5">
                  <Button padding="8px 20px">Submit</Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="w-full md:w-2/6">
            <img className="event-image" src={feedback} alt="event" />
          </div>
        </Container>
      </GetInvolved>
    </PageTemplate>
  );
};

const HeadingStyles = css`
  color: #fff;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 38.8px;

  @media only screen and (${screen.md}) {
    font-size: 64px;
    line-height: 76.8px;
  }
`;
const GetInvolved = styled.div`
  display: flex;
  background: #fdf5d9;
  padding: 98px 24px;
  flex-direction: column;
  background: ${({ background }) => background || "#fdf5d9"};

  p.invite-text {
    color: #000;
    ont-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  p.invite-sub-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media only screen and (${screen.md}) {
    padding: 98px 60px;
    flex-direction: row;

    .invite-img {
      height: auto;
    }

    p.invite-text {
      font-size: 64px;
      line-height: 81.92px;
    }
    p.invite-sub-text {
      font-size: 36px;
      line-height: 128%;
    }
  }
`;
const Hero = styled.section`
  min-height: 40vh;

  background-image: url(${aboutHero});
  background-size: cover;
  background-position: center;
  position: relative;

  .bg-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    background-color: rgba(98, 51, 0, 0.7);
  }
`;
const HeroHeading = styled.h1`
  ${HeadingStyles};
`;
