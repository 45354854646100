import { Form, Switch } from "antd";
import React, { useState } from "react";
import { Button, Input, Text } from "../../../../components";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

export const SecurityTab = () => {
  const [enableTwoFactorAuth, setEnableTwoFactorAuth] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({});
  const [form] = Form.useForm();
  const axiosPrivate = useAxiosPrivate();

  async function handleSubmit() {
    try {
      const response = await axiosPrivate.post(
        `/api/change-password`,
        passwordInfo
      );
      if (response.data) {
        toast.success("Password Change was successful!");
      }
    } catch (e) {
      toast.error("An error occured while trying to change password!");
    }
  }

  return (
    <div className="pt-6 pb-6">
      <Text level={5} style={{ color: "#5b5858" }}>
        Two-factor Authentication
      </Text>

      <div className="flex flex-col gap-4 mt-8 mb-8">
        <div className="flex gap-4">
          <Switch
            defaultChecked={enableTwoFactorAuth}
            disabled
            onChange={(val) => setEnableTwoFactorAuth(val)}
          />
          <Text type="p">
            I want to receive weekly notifications for events, sermons and
            activities
          </Text>
        </div>
      </div>

      <Text level={5} style={{ color: "#5b5858" }}>
        Change Password
      </Text>

      <Form
        form={form}
        name="register"
        onFinish={handleSubmit}
        scrollToFirstError
      >
        <div className="flex flex-col gap-2 mt-8 w-full md:w-1/2 lg:w-1/3">
          <Form.Item
            name="password"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input
              type="password"
              placeholder="enter password"
              value={passwordInfo.password}
              onChange={(e) =>
                setPasswordInfo((prev) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              label="Password"
              id="password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            style={{ width: "100%" }}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("This value must match your password")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input
              type="password"
              placeholder="Confirm password"
              value={passwordInfo.confirm}
              onChange={(e) =>
                setPasswordInfo((prev) => ({
                  ...prev,
                  confirmPassword: e.target.value,
                }))
              }
              label="Confirm password"
              id="confirmPassword"
            />
          </Form.Item>
        </div>

        <div className="flex justify-end mt-4">
          <div className="w-full md:w-1/6">
            <Button type="submit">Change password</Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
