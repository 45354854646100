import React from "react";
import { PageTemplate } from "../../templates/PageTemplate";
import styled, { css } from "styled-components";
import aboutHero from "../../assets/images/static_assets/about_hero.jpg";
import { Container } from "../../components/atoms/Container";
import { screen } from "../../theme";
import gtbLogo from "../../assets/bank_icons/gtco.png";
import stanbicLogo from "../../assets/bank_icons/stanbic.png";
import zenithLogo from "../../assets/bank_icons/zenith.png";
import wemaLogo from "../../assets/bank_icons/wema.jpeg";

import { GivingDetailsContainer } from "../../components/organisms/GivingDetailsContainer";

export const Give = () => {
  return (
    <PageTemplate>
      <Hero>
        <div className="bg-filter">
          <HeroHeading>Support your faith with your generosity.</HeroHeading>
        </div>
      </Hero>

      <GivingInvitation background="#fff">
        <Container className="flex flex-col md:flex-row gap-12">
          <div className="flex flex-column gap-4 w-full md:w-2/4">
            <p className="thank-you-text">Thank you for giving.</p>
          </div>
          <div className="flex flex-column gap-4 w-full md:w-2/4">
            <p>
              You have the wonderful opportunity to sow into the ministries of
              TCN Global and also support the endeavors of TCN Lekki Local
              Church.
            </p>

            <p>
              Your generosity enables us to continue spreading the message of
              hope and love. Please find the account details for both below
            </p>
          </div>
        </Container>
      </GivingInvitation>

      <Container>
        <GivingDetailsContainer
          title="TCN Global"
          background="#603502"
          color="white"
          localAccounts={[
            {
              logo: gtbLogo,
              bankName: "GUARANTY TRUST BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0010471264",
            },
            {
              logo: zenithLogo,
              bankName: "ZENITH BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "1010845720",
            },
            {
              logo: stanbicLogo,
              bankName: "STANBIC IBTC BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0003699923",
            },
          ]}
          domAccounts={[
            {
              logo: gtbLogo,
              bankName: "GUARANTY TRUST BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0114030385",
            },
            {
              logo: stanbicLogo,
              bankName: "STANBIC IBTC BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0036649009",
            },
          ]}
        />

        <GivingDetailsContainer
          title="TCN Lekki"
          color="black"
          localAccounts={[
            {
              logo: gtbLogo,
              bankName: "GUARANTY TRUST BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0010471264",
            },
            {
              logo: zenithLogo,
              bankName: "ZENITH BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "1010845720",
            },
            {
              logo: stanbicLogo,
              bankName: "STANBIC IBTC BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0003699923",
            },
            {
              logo: wemaLogo,
              bankName: "WEMA BANK",
              accountName: "TCN LEKKI WELFARE",
              accountNumber: "8270014404",
            },
            {
              logo: wemaLogo,
              bankName: "WEMA BANK",
              accountName: "TCN LEKKI PROJECTS",
              accountNumber: "8270014413",
            },
          ]}
          domAccounts={[
            {
              logo: gtbLogo,
              bankName: "GUARANTY TRUST BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0114030385",
            },
            {
              logo: stanbicLogo,
              bankName: "STANBIC IBTC BANK",
              accountName:
                "Covenant Christian World Assembly (Covenant Christian Centre)",
              accountNumber: "0036649009",
            },
          ]}
        />
      </Container>
    </PageTemplate>
  );
};

const HeadingStyles = css`
  color: ${({ color }) => color || "#fff"};
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 38.8px;

  @media only screen and (${screen.md}) {
    font-size: 48px;
    line-height: 76.8px;
  }
`;
const GivingInvitation = styled.div`
  display: flex;
  background: #fdf5d9;
  padding: 98px 24px;
  flex-direction: column;
  background: ${({ background }) => background || "#fdf5d9"};

  p.thank-you-text {
    color: #000;
    ont-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  p.thank-you-sub-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media only screen and (${screen.md}) {
    padding: 98px 60px;
    flex-direction: row;

    .invite-img {
      height: auto;
    }

    p.thank-you-text {
      font-size: 36px;
      line-height: 81.92px;
    }
    p.thank-you-sub-text {
      font-size: 36px;
      line-height: 128%;
    }
  }
`;
const Hero = styled.section`
  min-height: 50vh;

  background-image: url(${aboutHero});
  background-size: cover;
  background-position: center;
  position: relative;

  .bg-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;
const HeroHeading = styled.h1`
  ${HeadingStyles};
`;
