import { Modal } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Text } from "../../atoms";

export const RegistrationSuccessModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  return (
    <Modal
      centered
      open={open}
      title="Registration successful!"
      onOk={() => {
        setOpen(false);
        navigate("/login", { replace: true });
      }}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <div className="flex flex-col gap-4">
        <Text type="p">
          Please visit your email to activate your account, then proceed to
          login.
        </Text>
      </div>

      <div className="flex flex-col gap-2 mt-2">
        <Link to="/login">
          <Button type="submit">Proceed to login</Button>
        </Link>
      </div>
    </Modal>
  );
};
