import { Tabs } from "antd";
import React from "react";
import styled from "styled-components";
import { EditProfileTab } from "./_partials/EditProfileTab";
import { PreferencesTab } from "./_partials/PreferencesTab";
import { SecurityTab } from "./_partials/SecurityTab";
// import facebook from "../../assets/svgs/facebook.svg";

export const Settings = () => {
  const onChange = (key) => {};
  const items = [
    {
      key: "1",
      label: "Edit Profile",
      children: <EditProfileTab />,
    },
    {
      key: "2",
      label: "Preferences",
      children: <PreferencesTab />,
    },
    {
      key: "3",
      label: "Security",
      children: <SecurityTab />,
    },
  ];

  return (
    <Wrapper>
      <TabSection>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </TabSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const TabSection = styled.section`
  background-color: white;
  padding: 12px 24px 32px 24px;
  border-radius: 12px;
`;
