import React from "react";

export const Icons = (props) => {
  if (props.type === "hamburger") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          fill="#E36725"
          d="M3 6h18v2.016H3V6Zm0 6.984v-1.968h18v1.968H3ZM3 18v-2.016h18V18H3Z"
        />
      </svg>
    );
  }

  if (props.type === "mobile-menu-icon") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={44}
        height={44}
        fill="none"
        {...props}
      >
        <g strokeWidth={2.75}>
          <path
            stroke="#E36725"
            d="M9.167 25.666a3.667 3.667 0 1 0 0-7.333 3.667 3.667 0 0 0 0 7.333Z"
          />
          <path
            stroke="#F5CB41"
            d="M22 25.666a3.667 3.667 0 1 0 0-7.333 3.667 3.667 0 0 0 0 7.333Z"
            opacity={0.5}
          />
          <path
            stroke="#E36725"
            d="M34.833 25.666a3.667 3.667 0 1 0 0-7.333 3.667 3.667 0 0 0 0 7.333Z"
          />
        </g>
      </svg>
    );
  }

  if (props.type === "close") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          fill="#0A0A09"
          d="M18.984 6.422 13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578 1.406 1.406Z"
          opacity={0.5}
        />
      </svg>
    );
  }

  if (props.type === "home") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={32}
        fill="none"
        {...props}
      >
        <g fill="#292D32">
          <path d="M28.474 15.449H4.83a2.31 2.31 0 0 1-2.3-2.3V8.79c0-.893.618-1.8 1.446-2.128l11.822-4.729c.499-.197 1.208-.197 1.708 0l11.822 4.729c.827.328 1.445 1.248 1.445 2.128v4.36a2.31 2.31 0 0 1-2.3 2.3ZM16.652 3.745c-.053 0-.105 0-.132.013L4.711 8.487c-.079.04-.21.21-.21.302v4.36c0 .185.145.33.329.33h23.644a.325.325 0 0 0 .328-.33V8.79c0-.092-.118-.263-.21-.302L16.77 3.757c-.026-.012-.066-.012-.118-.012ZM29.788 29.898H3.516a.992.992 0 0 1-.985-.985v-3.94a2.31 2.31 0 0 1 2.299-2.3h23.644a2.31 2.31 0 0 1 2.299 2.3v3.94a.992.992 0 0 1-.985.985ZM4.5 27.928h24.301v-2.955a.325.325 0 0 0-.328-.329H4.83a.325.325 0 0 0-.329.329v2.955Z" />
          <path d="M6.143 24.644a.992.992 0 0 1-.985-.985v-9.195c0-.539.446-.986.985-.986.538 0 .985.447.985.986v9.195a.992.992 0 0 1-.985.985ZM11.397 24.644a.992.992 0 0 1-.985-.985v-9.195c0-.539.447-.986.985-.986.539 0 .986.447.986.986v9.195a.992.992 0 0 1-.986.985ZM16.652 24.644a.992.992 0 0 1-.986-.985v-9.195c0-.539.447-.986.986-.986.538 0 .985.447.985.986v9.195a.992.992 0 0 1-.985.985ZM21.906 24.644a.992.992 0 0 1-.985-.985v-9.195c0-.539.447-.986.985-.986.539 0 .985.447.985.986v9.195a.992.992 0 0 1-.985.985ZM27.16 24.644a.992.992 0 0 1-.985-.985v-9.195c0-.539.446-.986.985-.986.539 0 .985.447.985.986v9.195a.992.992 0 0 1-.985.985ZM31.101 29.898H2.202a.992.992 0 0 1-.985-.985c0-.539.447-.985.985-.985h28.9c.538 0 .984.446.984.985a.992.992 0 0 1-.985.985ZM16.652 12.165a2.96 2.96 0 0 1-2.956-2.956 2.96 2.96 0 0 1 2.956-2.955 2.96 2.96 0 0 1 2.955 2.955 2.96 2.96 0 0 1-2.955 2.956Zm0-3.94a.992.992 0 0 0-.985.984c0 .539.446.986.985.986a.992.992 0 0 0 .985-.986.992.992 0 0 0-.985-.985Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "globe") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={33}
        height={32}
        fill="none"
        {...props}
      >
        <g
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.97}
        >
          <path d="M16.651 28.913c7.255 0 13.136-5.88 13.136-13.136 0-7.254-5.88-13.135-13.136-13.135-7.254 0-13.135 5.88-13.135 13.135s5.88 13.136 13.135 13.136Z" />
          <g opacity={0.4}>
            <path d="M11.397 3.955h1.314a37.336 37.336 0 0 0 0 23.645h-1.314M20.592 3.955a37.336 37.336 0 0 1 0 23.645" />
            <path d="M4.83 21.031v-1.313a37.338 37.338 0 0 0 23.644 0v1.313M4.83 11.837a37.337 37.337 0 0 1 23.644 0" />
          </g>
        </g>
      </svg>
    );
  }

  if (props.type === "event") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={35}
        fill="none"
        {...props}
      >
        <path
          fill="#666"
          d="M26.99 27.453V11.88H7.158v15.573H26.99ZM22.731 1.897h2.862V4.76h1.397c.755 0 1.42.289 1.997.865.577.577.865 1.243.865 1.997v19.832c0 .754-.288 1.42-.865 1.997-.577.576-1.242.865-1.997.865H7.158c-.799 0-1.486-.289-2.063-.866-.532-.576-.799-1.242-.799-1.996V7.621c0-.754.267-1.42.799-1.997.577-.576 1.264-.865 2.063-.865h1.398V1.897h2.861V4.76h11.314V1.897Zm1.464 15.64v7.12h-7.12v-7.12h7.12Z"
        />
      </svg>
    );
  }

  if (props.type === "team") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={34}
        fill="none"
        {...props}
      >
        <g
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.124}
        >
          <path d="M25.49 10.141a.855.855 0 0 0-.268 0 3.645 3.645 0 0 1-3.513-3.654 3.645 3.645 0 0 1 3.654-3.654 3.654 3.654 0 0 1 3.654 3.654 3.667 3.667 0 0 1-3.526 3.654ZM24.032 20.452c1.94.326 4.08-.014 5.58-1.02 1.998-1.331 1.998-3.512 0-4.844-1.515-1.005-3.682-1.345-5.622-1.005M8.452 10.141a.855.855 0 0 1 .27 0 3.645 3.645 0 0 0 3.512-3.654A3.645 3.645 0 0 0 8.58 2.833a3.654 3.654 0 0 0-3.654 3.654 3.667 3.667 0 0 0 3.526 3.654ZM9.911 20.452c-1.94.326-4.079-.014-5.58-1.02-1.997-1.331-1.997-3.512 0-4.844 1.515-1.005 3.682-1.345 5.623-1.005M16.992 20.72a.855.855 0 0 0-.269 0 3.645 3.645 0 0 1-3.512-3.654 3.645 3.645 0 0 1 3.654-3.654 3.654 3.654 0 0 1 3.654 3.654c-.014 1.983-1.572 3.598-3.526 3.654ZM21.113 25.182c-2.252-1.502-5.977-1.502-8.243 0-1.997 1.331-1.997 3.512 0 4.844 2.266 1.515 5.977 1.515 8.243 0" />
        </g>
      </svg>
    );
  }

  if (props.type === "profile") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={35}
        fill="none"
        {...props}
      >
        <g
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.13}
        >
          <path d="M21.362 4.774a7.02 7.02 0 0 0-4.288-1.434 7.101 7.101 0 0 0-7.098 7.099 7.101 7.101 0 0 0 7.098 7.098 7.101 7.101 0 0 0 7.1-7.098M29.27 31.734c0-5.494-5.466-9.938-12.195-9.938-6.73 0-12.196 4.444-12.196 9.938" />
        </g>
      </svg>
    );
  }

  if (props.type === "edit") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={28}
        height={27}
        fill="none"
        {...props}
      >
        <path
          stroke="#6F6F6F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.65}
          d="M12.847 2.7h-2.2C5.15 2.7 2.95 4.9 2.95 10.398v6.599c0 5.499 2.2 7.698 7.699 7.698h6.598c5.5 0 7.699-2.2 7.699-7.698v-2.2"
        />
        <path
          stroke="#6F6F6F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.65}
          d="m18.39 3.821-8.666 8.667c-.33.33-.66.978-.726 1.451l-.473 3.31c-.176 1.2.671 2.035 1.87 1.87l3.31-.473c.462-.066 1.11-.396 1.452-.726l8.666-8.666c1.496-1.495 2.2-3.233 0-5.433-2.2-2.2-3.937-1.495-5.433 0Z"
        />
        <path
          stroke="#6F6F6F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.65}
          d="M17.148 5.064a7.857 7.857 0 0 0 5.432 5.433"
        />
      </svg>
    );
  }

  return null;
};
