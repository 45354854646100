import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  width: ${({ width }) => width || "100%"};
  padding: ${({ padding }) => padding || "16.113px 56.394px"};
  border: ${({ border }) => border || "1px solid #E36725"};
  justify-content: center;
  align-items: center;
  border-radius: 6.905px;
  background: ${({ bgColor }) => bgColor || "#E36725"};
  color: ${({ color }) => color || "#fff"};
  white-space: nowrap;
`;
export const Button = ({ children, onClick, className, ...rest }) => {
  return (
    <StyledButton className={className} onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  );
};
