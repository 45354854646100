import React from "react";
import { PageTemplate } from "../../templates/PageTemplate";
import styled, { css } from "styled-components";
import upcomingEventBg from "../../assets/images/static_assets/events_hero.jpg";
import { Container } from "../../components/atoms/Container";
import { screen } from "../../theme";
import { useQuery } from "@tanstack/react-query";
import axiosPublic from "../../api/axios";
import { Skeleton } from "antd";
import { EventCard2 } from "../../components/molecules/EventCard2";
import { EventCard3 } from "../../components/molecules/EventCard3";

export const Events = () => {
  const {
    isPending,
    error,
    data: events,
  } = useQuery({
    queryKey: ["events"],
    queryFn: async () => {
      const response = await axiosPublic.get("/api/events/categories");
      return response.data;
    },
  });

  if (isPending) return <Skeleton active />;

  if (error) return "An error occured while fetching departments";

  return (
    <PageTemplate>
      <UpcomingEvents>
        <div className="bg-filter">
          <Container className="flex flex-col gap-4 justify-center items-center mb-16">
            <div className="flex flex-col items-center">
              <HeroHeading>Upcoming Events</HeroHeading>
              <HeroSubText>
                View our upcoming events here for the month of december
              </HeroSubText>
            </div>

            <div className="w-full flex flex-col md:flex-row justify-center gap-4">
              {events.upcomingEvents.map((item) => (
                <div className="w-full sm:w-1/2 md:w-1/3 flex flex-wrap">
                  <EventCard3
                    key={item._id}
                    img={item.image}
                    name={item.eventName}
                    date={item.date}
                    time={item.time}
                    regLink={item.registrationLink}
                    eventType={item.type}
                  />
                </div>
              ))}
            </div>
          </Container>
        </div>
      </UpcomingEvents>

      <RecentEvents>
        <Container className="flex flex-col gap-4 justify-center items-center mt-16 w-full">
          <div className="w-full">
            <Heading>Recent Events</Heading>
            <Subheading>
              View our upcoming events here for the month of december
            </Subheading>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-center gap-4">
            {events.recentEvents.map((item) => (
              <div className="w-full sm:w-1/2 md:w-1/4 flex flex-wrap">
                <EventCard2
                  key={item._id}
                  width="100%"
                  bgImage={item.image}
                  title={item.eventName}
                  date={item.date}
                  time={item.time}
                  regLink={item.registrationLink}
                  type={item.type}
                />
              </div>
            ))}
          </div>
        </Container>
      </RecentEvents>
    </PageTemplate>
  );
};

const HeadingStyles = css`
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 38.8px;
  text-align: center;

  @media only screen and (${screen.md}) {
    font-size: 64px;
    line-height: 76.8px;
  }
`;
const SubHeadingStyles = css`
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
  text-align: center;

  @media only screen and (${screen.md}) {
    font-size: 24px;
    line-height: 36px;
  }
`;
const Heading = styled.h1`
  ${HeadingStyles};
  color: black;
`;
const Subheading = styled.p`
  ${SubHeadingStyles};
  color: black;
  margin-bottom: 12px;
`;
const RecentEvents = styled.div`
  background: #fdf5d9;
  padding: 24px 0px 98px 0;

  @media only screen and (${screen.md}) {
    padding: 98px 0px;
  }
`;
const UpcomingEvents = styled.div`
  background-image: url(${upcomingEventBg});
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  position: relative;

  .bg-filter {
    padding: 48px 0 24px 0;
    position: static;
    background: rgba(96, 53, 2, 0.97);
    background: linear-gradient(
      146deg,
      rgba(96, 53, 2, 0.97) 0%,
      rgba(8, 7, 5, 0.97) 70%,
      rgba(8, 7, 5, 0.97) 100%
    );
  }

  @media only screen and (${screen.md}) {
    .bg-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }
  }
`;
const HeroHeading = styled.h1`
  ${HeadingStyles};
`;
const HeroSubText = styled.p`
  ${SubHeadingStyles};
  margin-bottom: 12px;
`;
