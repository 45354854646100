import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { Button, Input, Text } from "../../components/atoms";
import styled from "styled-components";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { arrayRange } from "../../utils";
import { toast } from "react-toastify";
import { Checkbox, Form } from "antd";
import useAuth from "../../hooks/useAuth";
import { RegistrationSuccessModal } from "../../components/organisms/RegistrationSuccessModal";
import { useQuery } from "@tanstack/react-query";
import axiosPublic from "../../api/axios";
import bgImage from "../../assets/images/register-img.png";

const LabelInputs = styled.label`
  input[type="radio"]:checked + p {
    border: 1px solid orange;
  }

  input[type="radio"] {
    display: none;
  }

  p {
    color: red;
    color: #000;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12.624px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.034px;
    display: flex;
    padding: 7.214px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5.41px;
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

export const Register = () => {
  const [form] = Form.useForm();
  const auth = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({ ageGroup: "18 - 25" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // const {
  //   isPending,
  //   error,
  //   data: departments,
  // } = useQuery({
  //   queryKey: ["departments"],
  //   queryFn: async () => {
  //     const response = await axiosPublic("/api/departments");
  //     return response.data;
  //   },
  // });

  const {
    // isPending: dropdownsPending,
    // error: dropdownFetchError,
    data: dropdowns,
  } = useQuery({
    queryKey: ["dropdowns"],
    queryFn: async () => {
      const response = await axiosPublic("/api/users/dropdowns");
      return response.data;
    },
  });

  const handleSubmit = async () => {
    const payload = {
      ...userInfo,
      birthDay: `${userInfo.dayOfBirth}/${userInfo.monthOfBirth}`,
    };
    delete payload.dayOfBirth;
    delete payload.monthOfBirth;

    try {
      const response = await axios.post("/api/signup", payload);

      // Set the auth data here i.e login user on success
      if (response?.data) {
        // toast.success(
        //   "Sign up initiated successfully! Please check your email to activate account."
        // );
        // setAuth(response.data);
        // navigate("/", { replace: true });
        setShowSuccessModal(true);
      }
    } catch (err) {
      toast.error(err.response?.data?.error ?? "An error has occured");
    }
  };

  useEffect(() => {
    if (auth?.user) {
      navigate(from, { replace: true });
    }
  }, [auth, from, navigate]);

  return (
    <>
      <AuthTemplate bgImg={bgImage}>
        <Form
          form={form}
          name="register"
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <div className="flex flex-col items-center mb-3">
            <Text type="h1">Sign Up</Text>
            <Text>Enter details to create your account</Text>
          </div>
          <div className="flex flex-col">
            <div className="flex gap-4">
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your firstName",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Firstname"
                  value={userInfo.firstName}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                  label="Firstname"
                  id="firstName"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Last name",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={userInfo.surName}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      surName: e.target.value,
                    }))
                  }
                  label="Last Name"
                  id="surName"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="email"
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="enter email"
                  value={userInfo.email}
                  onChange={(e) =>
                    setUserInfo((prev) => ({ ...prev, email: e.target.value }))
                  }
                  label="Email"
                  id="email"
                  prefix={<MailOutlined />}
                />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="tel"
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "tel",
                    message: "The input is not telephone number",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter phone number"
                  value={userInfo.phoneNumber}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      phoneNumber: e.target.value,
                    }))
                  }
                  label="Phone number"
                  id="tel"
                />
              </Form.Item>

              <Form.Item
                name="industry"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select an industry you work in!",
                  },
                ]}
              >
                <Input
                  type="select"
                  options={
                    dropdowns?.industry?.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  showSearch
                  onChange={(val) => {
                    setUserInfo((prev) => ({ ...prev, industry: val }));
                  }}
                  label="Profession"
                  id="industry"
                />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="maritalStatus"
                style={{ width: "100%" }}
                rules={[
                  { required: true, message: "Please select marital status!" },
                ]}
              >
                <Input
                  type="select"
                  options={[
                    { label: "Single", value: "Single" },
                    { label: "Married", value: "Married" },
                    { label: "Divorced", value: "Divorced" },
                    { label: "Widowed", value: "Widowed" },
                    { label: "Other", value: "Other" },
                  ]}
                  showSearch
                  onChange={(val) => {
                    setUserInfo((prev) => ({ ...prev, maritalStatus: val }));
                  }}
                  label="Marital Status"
                  id="maritalStatus"
                />
              </Form.Item>

              <Form.Item name="Number of Children" style={{ width: "100%" }}>
                <Input
                  type="text"
                  placeholder="Enter number of children"
                  value={userInfo.noOfChildren}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      noOfChildren: e.target.value,
                    }))
                  }
                  label="Number of Children"
                  id="children"
                />
              </Form.Item>

              <Form.Item
                name="monthOfBirth"
                style={{ width: "100%" }}
                rules={[
                  { required: true, message: "Please select month of birth!" },
                ]}
              >
                <Input
                  type="select"
                  options={[
                    { value: "1", label: "January" },
                    { value: "2", label: "February" },
                    { value: "3", label: "March" },
                    { value: "4", label: "April" },
                    { value: "5", label: "May" },
                    { value: "6", label: "June" },
                    { value: "7", label: "July" },
                    { value: "8", label: "August" },
                    { value: "9", label: "September" },
                    { value: "10", label: "October" },
                    { value: "11", label: "November" },
                    { value: "12", label: "December" },
                  ]}
                  showSearch
                  placeholder="Month number or select"
                  onChange={(val) =>
                    setUserInfo((prev) => ({ ...prev, monthOfBirth: val }))
                  }
                  label="Month of birth"
                  id="monthOfBirth"
                />
              </Form.Item>
            </div>

            <div className="flex flex-col md:flex-row gap-x-4">
              <Form.Item
                name="dayOfBirth"
                style={{ width: "100%" }}
                rules={[
                  { required: true, message: "Please select day of birth!" },
                ]}
              >
                <Input
                  type="select"
                  options={arrayRange(1, 31).map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  showSearch
                  placeholder="Day of birth"
                  onChange={(val) =>
                    setUserInfo((prev) => ({ ...prev, dayOfBirth: val }))
                  }
                  label="Day of birth"
                  id="dayOfBirth"
                />
              </Form.Item>

              <Form.Item
                name="gender"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Please select gender!" }]}
              >
                <Input
                  type="select"
                  options={[
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                  ]}
                  onChange={(val) =>
                    setUserInfo((prev) => ({ ...prev, gender: val }))
                  }
                  label="Gender"
                  id="gender"
                />
              </Form.Item>

              <Form.Item
                name="residentialArea"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please where you live around lagos!",
                  },
                ]}
              >
                <Input
                  type="select"
                  options={
                    dropdowns?.areas?.map((item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    }) || []
                  }
                  showSearch
                  onChange={(val) => {
                    setUserInfo((prev) => ({ ...prev, residentialArea: val }));
                  }}
                  label="Area"
                  id="residentialArea"
                />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-2 mt-2 mb-6">
              <label> Age group </label>
              <div className="flex flex-wrap gap-2">
                <LabelInputs>
                  <input
                    type="radio"
                    name="age-bracket"
                    onChange={() =>
                      setUserInfo((prev) => ({
                        ...prev,
                        ageGroup: "Under 18",
                      }))
                    }
                  />
                  <p>Under 18</p>
                </LabelInputs>
                <LabelInputs>
                  <input
                    type="radio"
                    name="age-bracket"
                    onChange={() =>
                      setUserInfo((prev) => ({
                        ...prev,
                        ageGroup: "18 - 25",
                      }))
                    }
                    checked={userInfo.ageGroup === "18 - 25"}
                  />
                  <p>18 - 25</p>
                </LabelInputs>
                <LabelInputs>
                  <input
                    type="radio"
                    name="age-bracket"
                    onChange={() =>
                      setUserInfo((prev) => ({
                        ...prev,
                        ageGroup: "26 - 35",
                      }))
                    }
                  />
                  <p>26 - 35</p>
                </LabelInputs>
                <LabelInputs>
                  <input
                    type="radio"
                    name="age-bracket"
                    onChange={() =>
                      setUserInfo((prev) => ({
                        ...prev,
                        ageGroup: "36 - 45",
                      }))
                    }
                  />
                  <p>36 - 45</p>
                </LabelInputs>
                <LabelInputs>
                  <input
                    type="radio"
                    name="age-bracket"
                    onChange={() =>
                      setUserInfo((prev) => ({
                        ...prev,
                        ageGroup: "46 - 55",
                      }))
                    }
                  />
                  <p>46 - 55</p>
                </LabelInputs>
                <LabelInputs>
                  <input
                    type="radio"
                    name="age-bracket"
                    onChange={() =>
                      setUserInfo((prev) => ({
                        ...prev,
                        ageGroup: "56 and above",
                      }))
                    }
                  />
                  <p>56 and above</p>
                </LabelInputs>
              </div>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="password"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input
                  type="password"
                  placeholder="enter password"
                  value={userInfo.password}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  label="Password"
                  id="password"
                />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                style={{ width: "100%" }}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("This value must match your password")
                      );
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input
                  type="password"
                  placeholder="Confirm password"
                  value={userInfo.confirm}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }))
                  }
                  label="Confirm password"
                  id="confirmPassword"
                />
              </Form.Item>
            </div>

            <div className="flex flex-col md:flex-row gap-x-4">
              <Form.Item name="why-join" style={{ width: "100%" }}>
                <Input
                  type="textarea"
                  name="whyDidYouJoinTcnLekki"
                  placeholder="type here."
                  rows="3"
                  onChange={(e) => {
                    setUserInfo((prev) => ({
                      ...prev,
                      whyDidYouJoinTcnLekki: e.target.value,
                    }));
                  }}
                  label="Why did you join TCN, Lekki"
                  id="why-join"
                />
              </Form.Item>
            </div>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => {
                    setUserInfo((prev) => ({
                      ...prev,
                      consent: value,
                    }));
                    return value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should accept agreement"));
                  },
                },
              ]}
            >
              <Checkbox>
                I confirm that i have read and agreed to{" "}
                <a
                  style={{ color: "#E36725", textDecoration: "underline" }}
                  href="https://www.insightsforliving.org/privacypolicy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  TCN Lekki Terms of use and privacy policy
                </a>
              </Checkbox>
            </Form.Item>

            {/* <Form.Item
              name="consentToReceiveUpdates"
              valuePropName="consentToReceiveUpdates"
              rules={[
                {
                  validator: (_, value) => {
                    setUserInfo((prev) => ({
                      ...prev,
                      consentToReceiveUpdates: value,
                    }));
                    return true;
                  },
                },
              ]}
            >
              <Checkbox>
                I would like to receive emails and updates from TCN Lekki.
              </Checkbox>
            </Form.Item> */}

            <div className="flex flex-col gap-2">
              <Button type="submit">Sign up</Button>

              <Link to="/login">
                <Text className="text-center">
                  Already have an account? Login
                </Text>
              </Link>
            </div>
          </div>
        </Form>
      </AuthTemplate>
      {showSuccessModal && (
        <RegistrationSuccessModal
          open={showSuccessModal}
          setOpen={setShowSuccessModal}
        />
      )}
    </>
  );
};
