import React, { useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { Button, Input, Text } from "../../components/atoms";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`/api/reset-password`, {
        resetToken: searchParams.get("token"),
        newPassword: password,
      });
      if (response.data) {
        toast.success("Password reset successful");
        navigate("/dashboard", { replace: true });
      }
    } catch (err) {
      toast.error("An error occured while resetting password");
    }
  };

  return (
    <AuthTemplate>
      <div className="flex flex-col mt-8 mb-3">
        <Text type="h1">Reset Password</Text>
        <Text>Enter your new password.</Text>
      </div>

      <Input
        type="password"
        placeholder="enter new password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label="Password"
        id="password"
      />

      <div className="mt-4">
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </AuthTemplate>
  );
};
