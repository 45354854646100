import React from "react";
import styled from "styled-components";
import notification1 from "../../assets/images/placeholders/notification_img_1.png";
import notification2 from "../../assets/images/placeholders/notification_img_2.png";
import { Text } from "../../components";

export const Notifications = () => {
  return (
    <Wrapper>
      <div className="flex gap-4 flex-col">
        {[
          {
            image: notification1,
            title: "Thanksgiving Service coming up this sunday",
            date: "12/12/2023",
            time: "8:45 am",
            label: "New",
          },
          {
            image: notification2,
            title: "Thanksgiving Service coming up this sunday",
            date: "12/12/2023",
            time: "8:45 am",
            label: "Update",
          },
        ].map(({ image, title, date, time, label }) => (
          <NotificationItem className="flex items-center gap-6">
            <img src={image} alt="" />
            <div className="w-full flex justify-between">
              <div className="flex flex-col gap-2">
                <Text level={4}>{title}</Text>
                <Text type="p">
                  {date} | {time}
                </Text>
              </div>
              <Label>{label}</Label>
            </div>
          </NotificationItem>
        ))}
      </div>

      <div className="flex gap-4 flex-col">
        <Text level={3} style={{ color: "#5b5858" }}>
          Older
        </Text>
        <div className="flex flex-col gap-4">
          {[
            {
              image: notification1,
              title: "Thanksgiving Service coming up this sunday",
              date: "12/12/2023",
              time: "8:45 am",
            },
            {
              image: notification2,
              title: "Thanksgiving Service coming up this sunday",
              date: "12/12/2023",
              time: "8:45 am",
            },
            {
              image: notification2,
              title: "Thanksgiving Service coming up this sunday",
              date: "12/12/2023",
              time: "8:45 am",
            },
          ].map(({ image, title, date, time, label }) => (
            <NotificationItem className="flex items-center gap-6">
              <img src={image} alt="" />
              <div className="w-full flex justify-between">
                <div className="flex flex-col gap-2">
                  <Text level={4}>{title}</Text>
                  <Text type="p">
                    {date} | {time}
                  </Text>
                </div>
                {label && <Label>{label}</Label>}
              </div>
            </NotificationItem>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Label = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 8px;
  background-color: #f5cb41;
  color: #020202;
`;
const NotificationItem = styled.div`
  padding-bottom: 24px;
  &:not(:last-child) {
    border-bottom: 1px solid #b2b0b0;
  }
`;
