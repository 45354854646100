import { AnimatePresence } from "framer-motion";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Login,
  Register,
  ForgotPassword,
  Home,
  DashboardEvents,
  Socials,
  Settings,
} from "./pages";
import { Events } from "./pages/Events";
import { Volunteer } from "./pages/Volunteer";
import { About } from "./pages/About";
import { AccountActivation } from "./pages/AccountActivation";
import { DashboardTemplate } from "./templates/DashboardTemplate";
import { Dashboard } from "./pages/Dashboard";
import { Notifications } from "./pages/Notifications";
import { ResetPassword } from "./pages/ResetPassword";
import { Give } from "./pages/Give";
import { DashboardGive } from "./pages/DashboardGive";
import { PrayerPoints } from "./pages/PrayerPoints";
import { DashboardVolunteer } from "./pages/DashboardVolunteer";
import { JoinCoreTeam } from "./pages/JoinCoreTeam";
import { ConnectGroup } from "./pages/ConnectGroup";
import RequireAuth from "./components/utils/RequireAuth";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <DashboardTemplate />
      </RequireAuth>
    ),
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
      {
        path: "/dashboard-events",
        element: <DashboardEvents />,
      },
      {
        path: "/socials",
        element: <Socials />,
      },
      {
        path: "/d/give",
        element: <DashboardGive />,
      },
      {
        path: "d/send-prayer",
        element: <PrayerPoints />,
      },
      {
        path: "d/volunteer",
        element: <DashboardVolunteer />,
      },
      {
        path: "d/connect-groups",
        element: <ConnectGroup />,
      },
      {
        path: "d/join-core-team",
        element: <JoinCoreTeam />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "/",
    index: true,
    element: <Home />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/give",
    element: <Give />,
  },
  {
    path: "/volunteer",
    element: <Volunteer />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/activate-account",
    element: <AccountActivation />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
]);

const App = () => {
  return (
    <AnimatePresence mode="wait">
      <RouterProvider router={router} />
    </AnimatePresence>
  );
};

export default App;
