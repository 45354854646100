import { useContext } from "react";
import axios from "../api/axios";
import AuthContext from "../store/contexts/AuthProvider";

const useRefreshToken = () => {
  const { setAuth } = useContext(AuthContext);

  const refresh = async () => {
    const response = await axios.get("/api/refresh", {
      withCredentials: true,
    });

    if (response.data) {
      setAuth(() => response.data);
    } else {
      setAuth(null);
    }

    return response.data.accessToken;
  };

  return refresh;
};

export default useRefreshToken;
