export const pageAnimationConfig = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1.4 },
};

export const arrayRange = (start, stop, step = 1) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

export function limitString(text) {
  if (!text) return "";
  if (text.length > 20) return `${text.substring(0, 20)}...`;
  return text;
}
