import React from "react";
import { PageTemplate } from "../../templates/PageTemplate";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../../components";
import background from "../../assets/images/static_assets/home_hero.jpg";
import upcomingEventsBg from "../../assets/images/static_assets/upcoming_events.jpg";
import inviteImage from "../../assets/images/invite.png";
import { Container } from "../../components/atoms/Container";
import { screen } from "../../theme";

import getInvolved from "../../assets/images/get_involved.png";
import { Card } from "../../components/molecules/Card";
import axiosPublic from "../../api/axios";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "antd";
import { GivingIntro } from "../../components/organisms/GivingIntro";

export const Home = () => {
  const {
    isPending,
    error,
    data: events,
  } = useQuery({
    queryKey: ["all-events"],
    queryFn: async () => {
      const response = await axiosPublic.get("/api/events/upcoming");
      return response.data;
    },
  });

  return (
    <PageTemplate>
      <Hero>
        <div className="bg-filter">
          <div className="w-full md:w-2/4 p-4">
            <HeroHeading>A people of Faith, a home of light</HeroHeading>
            <HeroSubText>
              Discover the warmth of our community, the strength of our faith,
              and the love of our fellowship.
            </HeroSubText>
          </div>

          <div className="flex gap-4">
            <Link to="/about">
              <Button padding="8px 20px">Learn More</Button>
            </Link>
            <a
              href="https://www.youtube.com/@tcnlekki/streams"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                bgColor="transparent"
                color="#fff"
                border="1px solid white"
                padding="8px 20px"
              >
                Watch Online
              </Button>
            </a>
          </div>
        </div>
      </Hero>

      <Container>
        <InviteSection className="mt-16 mb-16">
          <div className="invite-text-container w-full md:w-3/5">
            <p className="invite-text mb-4">
              Come worship with us this Sunday and experience a refreshing
              message.
            </p>
            <p className="invite-sub-text">
              We meet on Sundays by 7:45am and 10:00am for first and second
              services. Don’t miss out on this life changing experience.
            </p>
          </div>
          <div className="invite-img w-full md:w-2/5" />
        </InviteSection>
      </Container>

      <UpcomingEvents>
        <div className="bg-filter">
          <div className="p-4">
            <HeroHeading>Upcoming Events</HeroHeading>
            <HeroSubText>
              View our upcoming events here for the month of december
            </HeroSubText>
          </div>
          <div className="flex p-4 flex-wrap gap-4 justify-center">
            {isPending ? (
              <Skeleton active />
            ) : error ? (
              "An error occured while fetching departments"
            ) : (
              <>
                {events["upcomingEvents"].map((item) => (
                  <Card
                    className="w-full md:w-1/4"
                    key={item._id}
                    img={item.image}
                    name={item.eventName}
                    date={item.date}
                    time={item.time}
                    regLink={item.registrationLink}
                    eventType={item.type}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </UpcomingEvents>

      <Container className="flex flex-col md:flex-row gap-6 items-center">
        <GivingIntro />
      </Container>

      <CTA>
        <div className="w-full md:w-2/4 pr-6 pl-6 pt-8">
          <CTAHeading>
            Would you like to join the TCN Lekki Community?
          </CTAHeading>
          <CTASubheading>
            Please register with the link below and be updated on all our
            services and programs
          </CTASubheading>
        </div>

        <div className="flex gap-4">
          <Link to="/register">
            <Button padding="8px 20px">Register here</Button>
          </Link>
        </div>
      </CTA>

      <GetInvolved className="mt-16 mb-16">
        <Container className="flex flex-col md:flex-row gap-6 items-center">
          <div className="w-full flex flex-col gap-4 md:w-2/4">
            <p className="invite-text">Participate!</p>
            <p className="invite-sub-text">
              Would you like to volunteer with any department at the TCN Lekki
              Church, then you are right on track. We’ll love to have you join
              us.
            </p>

            <Link className="w-fit" to="/volunteer">
              <Button
                bgColor="transparent"
                color="#000"
                border="2px solid black"
                padding="8px 20px"
                width="fit-content"
              >
                Join us
              </Button>
            </Link>
          </div>
          <div className="w-full md:w-2/4">
            <img className="event-image" src={getInvolved} alt="event" />
          </div>
        </Container>
      </GetInvolved>
    </PageTemplate>
  );
};

const HeadingStyles = css`
  color: #fff;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 38.8px;

  @media only screen and (${screen.md}) {
    font-size: 64px;
    line-height: 76.8px;
  }
`;
const SubHeadingStyles = css`
  color: #fff;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;

  @media only screen and (${screen.md}) {
    font-size: 24px;
    line-height: 36px;
  }
`;
const GetInvolved = styled.div`
  display: flex;
  background: #fdf5d9;
  padding: 98px 24px;
  flex-direction: column;

  p.invite-text {
    color: #000;
    ont-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  p.invite-sub-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media only screen and (${screen.md}) {
    padding: 98px 60px;
    flex-direction: row;

    .invite-img {
      height: auto;
    }

    p.invite-text {
      font-size: 64px;
      line-height: 81.92px;
    }
    p.invite-sub-text {
      font-size: 36px;
      line-height: 128%;
    }
  }
`;
const CTAHeading = styled.h1`
  ${HeadingStyles};
  color: black;
`;
const CTASubheading = styled.p`
  ${SubHeadingStyles};
  color: black;
`;
const CTA = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
const UpcomingEvents = styled.div`
  background-image: url(${upcomingEventsBg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  position: relative;

  .bg-filter {
    padding: 48px 0 24px 0;
    position: static;
  }

  @media only screen and (${screen.md}) {
    .bg-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
    }
  }
`;
const InviteSection = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;

  .invite-text-container {
    background: #fdf5d9;
    padding: 98px 24px;
    border-radius: 24px;
  }

  p.invite-text {
    color: #000;
    font-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }
  p.invite-sub-text {
    color: #000;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .invite-img {
    background: url(${inviteImage});
    background-size: cover;
    background-position: center;
    height: 40vh;
    border-radius: 0 24px 24px 0;
  }

  @media only screen and (${screen.md}) {
    flex-direction: row;

    .invite-text-container {
      padding: 98px 60px;
    }

    .invite-img {
      height: auto;
    }

    p.invite-text {
      font-size: 64px;
      line-height: 81.92px;
    }
    p.invite-sub-text {
      font-size: 36px;
      line-height: 128%;
    }
  }
`;
const Hero = styled.section`
  min-height: 50vh;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  position: relative;

  .bg-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
  }

  @media only screen and (${screen.md}) {
    min-height: 80vh;
    gap: 32px;
  }
`;
const HeroHeading = styled.h1`
  ${HeadingStyles};
`;
const HeroSubText = styled.p`
  ${SubHeadingStyles};
`;
