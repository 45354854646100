import React from "react";
import styled, { css } from "styled-components";
import { Input as AntdInput, Select } from "antd";

const inputStyles = css`
  width: 100%;
  border-radius: 6.905px;
  border: ${({ border }) => border || "1.151px solid #e8e9ea"};
`;

const StyledInput = styled(AntdInput)`
  ${inputStyles};
`;
const StyledSelectInput = styled(Select)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextarea = styled(AntdInput.TextArea)`
  ${inputStyles};
  min-height: 200px;
`;
const Label = styled.label`
  margin-bottom: 3px;
`;

export const Input = ({
  value,
  onChange,
  type = "text",
  placeholder,
  label,
  id,
  options,
  size = "large",
  ...rest
}) => {
  if (type === "textarea")
    return (
      <div className="w-full">
        <Label htmlFor={id}> {label} </Label>
        <StyledTextarea
          type={type}
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          {...rest}
        ></StyledTextarea>
      </div>
    );

  if (type === "select") {
    return (
      <div className="w-full">
        <Label htmlFor={id}>{label}</Label>
        <StyledSelectInput
          options={options}
          onChange={onChange}
          size={size}
          {...rest}
        />
      </div>
    );
  }

  if (type === "password") {
    return (
      <div className="w-full">
        <Label htmlFor={id}> {label} </Label>
        <StyledInput.Password
          placeholder={placeholder}
          onChange={onChange}
          size={size}
          {...rest}
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <Label htmlFor={id}>{label}</Label>
      <StyledInput
        type={type}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        size={size}
        value={value}
        {...rest}
      />
    </div>
  );
};
