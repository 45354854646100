import React from "react";
import styled, { css } from "styled-components";

import { screen } from "../../../theme";
import { BankDetailsCard } from "../../molecules/BankDetailsCard";

export const GivingDetailsContainer = ({
  background,
  color,
  localAccounts = [],
  domAccounts = [],
  title,
  combined = true,
  subTitle = "Local Banks: In order to give locally, here are the account details",
  textAlign,
  cardTextAlign,
  cardItemsAlign,
}) => {
  return (
    <GivingDetailsWrapper
      background={background}
      color={color}
      className="flex flex-col gap-6 mt-16 mb-16"
    >
      <div>
        {title && (
          <HeroHeading $textAlign={textAlign} color={color}>
            {title}
          </HeroHeading>
        )}
        {subTitle && (
          <HeroSubText $textAlign={textAlign} color={color}>
            {subTitle}
          </HeroSubText>
        )}
      </div>

      {/* <div className="grid justify-items-center grid-cols-1 md:grid-cols-3  gap-y-12 gap-x-4 mt-12"> */}
      <div className="flex gap-16 flex-wrap">
        {localAccounts.map(({ logo, ...rest }, idx) => (
          <BankDetailsCard
            textAlign={cardTextAlign}
            alignItems={cardItemsAlign}
            key={idx}
            logo={logo}
            {...rest}
            item
          />
        ))}
      </div>

      {combined && (
        <>
          <HeroSubText color={color}>
            Domicilary Accounts: In order to give while abroad or in foreign
            currency, please utilize the details below
          </HeroSubText>

          {/* <div className="grid justify-items-center grid-cols-1 md:grid-cols-2  gap-y-12 gap-x-4 mt-12"> */}
          <div className="flex gap-16 flex-wrap">
            {domAccounts.map(({ logo, ...rest }, idx) => (
              <BankDetailsCard
                textAlign={cardTextAlign}
                alignItems={cardItemsAlign}
                key={idx}
                logo={logo}
                {...rest}
                item
              />
            ))}
          </div>
        </>
      )}
    </GivingDetailsWrapper>
  );
};

const HeadingStyles = css`
  color: ${({ color }) => color || "#fff"};
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  font-family: "Nunito Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 38.8px;

  @media only screen and (${screen.md}) {
    font-size: 48px;
    line-height: 76.8px;
  }
`;
const SubHeadingStyles = css`
  color: ${({ color }) => color || "#fff"};
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;

  @media only screen and (${screen.md}) {
    font-size: 24px;
    line-height: 36px;
  }
`;
const HeroHeading = styled.h1`
  ${HeadingStyles};
`;
const HeroSubText = styled.p`
  ${SubHeadingStyles};
`;
const GivingDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ background }) => background || "#fdf5d9"};
  padding: 48px;
  border-radius: 24px;
  color: ${({ color }) => color || "#000"};

  @media only screen and (${screen.md}) {
  }
`;
