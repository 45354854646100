import { format } from "date-fns";
import styled from "styled-components";
import { limitString } from "../../../utils";
import { Text } from "../../atoms";

const EventCardWrapper = styled.div`
  background-image: url(${({ bg_image }) => bg_image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: ${({ width }) => width || "428px"};
  height: ${({ height }) => height || "463px"};
  border-radius: ${({ borderRadius }) => borderRadius || '2%'};
  .event-details {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 24px;
  }
`;

export const EventCard2 = ({ bgImage, title, date, time, type, width, height }) => {
  return (
    <EventCardWrapper
      bg_image={bgImage}
      className="flex items-end gap-6"
      width={width}
      height={height}
    >
      <div className="event-details w-full flex flex-col gap-2">
        <Text style={{ color: "white", fontWeight: 500 }} level={4}>
          {limitString(title)}
        </Text>
        {type && (
          <Text style={{ color: "white" }} level={5}>
            {type}
          </Text>
        )}
        <Text level={5} style={{ color: "#F5CB41" }}>
          {format(new Date(date), "dd MMM, yyyy")} | {time}
        </Text>
      </div>
    </EventCardWrapper>
  );
};
