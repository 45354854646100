import React from "react";
import { Footer, Navbar } from "../../components";

export const PageTemplate = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};
