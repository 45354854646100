import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimationConfig } from "../../utils";
import logo from "../../assets/images/logo.png";
import bgImage from "../../assets/images/login-img.png";
import { Link } from "react-router-dom";
import { screen } from "../../theme";

const Wrapper = styled(motion.div)``;

export const AuthTemplate = ({ children, bgImg }) => {
  return (
    <Wrapper
      {...pageAnimationConfig}
      className="flex flex-col-reverse md:flex-row"
    >
      <div className="flex flex-col gap-4 w-full md:w-2/5 pr-4 pl-4 md:pl-8 md:pr-24 pt-8 pb-8 overflow-auto h-[100vh]">
        <StyledLink to="/">
          <LogoImg className="mb-2" src={logo} alt="logo" />
        </StyledLink>

        <div>{children}</div>
      </div>
      <ImgArea className="w-full md:w-3/5" $bgimg={bgImg}></ImgArea>
    </Wrapper>
  );
};

const StyledLink = styled(Link)`
  width: fit-content;
`;
const LogoImg = styled.img`
  width: 160px;
  height: 50px;
  margin: auto;

  @media only screen and (${screen.md}) {
    margin: 0;
  }
`;
const ImgArea = styled.div`
  background-image: url(${({ $bgimg }) => $bgimg || bgImage});
  background-size: cover;
  background-position-y: -50px;
  height: 20vh;
  background-repeat: no-repeat;

  @media only screen and (${screen.md}) {
    height: 100vh;
    background-position: center;
  }
`;
