import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./store/contexts/AuthProvider";
import PersistLogin from "./components/utils/PersistLogin";
import "./index.css";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <PersistLogin>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>

        <ToastContainer limit={3} />
      </React.StrictMode>
    </PersistLogin>
  </AuthProvider>
);
