import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Text } from "../../components";
import axios from "../../api/axios";
import { toast } from "react-toastify";

export const PrayerPoints = () => {
  const [request, setRequest] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/prayer-requests", { request });

      if (response?.data) {
        toast.success("Prayer request sent!");
        setRequest("");
      }
    } catch (err) {
      toast.error(err.response?.data?.error ?? "An error has occured");
    }
  };

  return (
    <Wrapper>
      <Text type="h1" className="pb-8">
        Please drop your prayer points below, we will like to agree with you in
        prayers
      </Text>
      <div className="w-full md:w-5/6 mb-8">
        <Input
          onChange={(e) => setRequest(e.target.value)}
          value={request}
          type="textarea"
          placeholder="I want you to..."
          rows="10"
        />
      </div>
      <Button size="large" width="fit-content" onClick={handleSubmit}>
        Submit
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  background-color: transparent;
`;
