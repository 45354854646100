import React, { useState } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";

import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input } from "../../../../components/atoms";
import { Loader } from "../../../../components/atoms/Loader";

import styled from "styled-components";
import profile from "../../../../assets/images/defaultDP.jpeg";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import axiosPublic from "../../../../api/axios";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

export const EditProfileTab = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const {
    isPending: isUserDetailsPending,
    // error: userDetailsError,
    // data: userDetails,
  } = useQuery({
    queryKey: ["user-details", auth?.user?._id],
    queryFn: async () => {
      if (!auth?.user?._id) return null;

      try {
        const response = await axiosPrivate(
          `api/users/get-user-details/${auth?.user?._id}`
        );
        if (response.data) {
          setUserInfo(response.data);
          form.setFieldsValue(response.data);
        }
        return response.data;
      } catch (error) {
        throw new Error(`Failed to fetch user details: ${error.message}`);
      }
    },
  });

  const {
    isPending,
    error,
    data: departments,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: async () => {
      const response = await axiosPublic("/api/departments");
      return response.data;
    },
  });

  const {
    // isPending: dropdownsPending,
    // error: dropdownFetchError,
    data: dropdowns,
  } = useQuery({
    queryKey: ["dropdowns"],
    queryFn: async () => {
      const response = await axiosPublic("/api/users/dropdowns");
      return response.data;
    },
  });

  function submitForm() {
    if (!auth?.user?._id) {
      return;
    }
    setLoading(true); // Set loading to true before API call
    axiosPrivate
      .put(`api/users/update-user-details/${auth?.user?._id}`, userInfo)
      .then((response) => {
        toast.success("Profile updated successfully");
        const timeoutID = setTimeout(() => {
          navigate("/dashboard", { replace: true });
        }, 3000);
        clearInterval(timeoutID);
      })
      .catch((error) => {
        console.error("Error updating profile:", error.message);
      })
      .finally(() => {
        setLoading(false); // Set loading back to false after API call
      });
  }

  if (isUserDetailsPending || !userInfo) return "Fetching user data...";

  return (
    <div className="flex flex-col md:flex-row gap-8 pt-4">
      <ProfileImgWrapper>
        <img
          style={{ width: "90px", height: "90px", borderRadius: "50%" }}
          src={profile}
          alt="profile"
        />
      </ProfileImgWrapper>
      <div className="">
        <Form
          form={form}
          name="register"
          onFinish={submitForm}
          scrollToFirstError
        >
          <div className="flex flex-col">
            <div className="flex gap-4">
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your firstName",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Firstname"
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                  label="Firstname"
                  id="firstName"
                  prefix={<UserOutlined />}
                />
              </Form.Item>

              <Form.Item
                name="surName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Last name",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      surName: e.target.value,
                    }))
                  }
                  label="Last Name"
                  id="surName"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col md:flex-row gap-x-4">
              <Form.Item
                name="email"
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="enter email"
                  onChange={(e) =>
                    setUserInfo((prev) => ({ ...prev, email: e.target.value }))
                  }
                  label="Email"
                  id="email"
                  prefix={<MailOutlined />}
                />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "tel",
                    message: "The input is not telephone number",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter phone number"
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      phoneNumber: e.target.value,
                    }))
                  }
                  label="Phone number"
                  id="phoneNumber"
                />
              </Form.Item>

              <Form.Item
                name="maritalStatus"
                style={{ width: "100%" }}
                rules={[
                  { required: true, message: "Please select marital status!" },
                ]}
              >
                <Input
                  type="select"
                  options={[
                    { label: "Single", value: "Single" },
                    { label: "Married", value: "Married" },
                    { label: "Divorced", value: "Divorced" },
                    { label: "Widowed", value: "Widowed" },
                    { label: "Other", value: "Other" },
                  ]}
                  onChange={(val) => {
                    setUserInfo((prev) => ({ ...prev, maritalStatus: val }));
                  }}
                  defaultValue={userInfo?.maritalStatus}
                  label="Marital Status"
                  id="maritalStatus"
                />
              </Form.Item>
            </div>

            <div className="flex flex-col md:flex-row gap-x-4">
              <Form.Item
                name="residentialArea"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please where you live around lagos!",
                  },
                ]}
              >
                <Input
                  type="select"
                  options={
                    dropdowns?.areas?.map((item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    }) || []
                  }
                  defaultValue={userInfo?.residentialArea}
                  onChange={(val) => {
                    setUserInfo((prev) => ({ ...prev, residentialArea: val }));
                  }}
                  label="Area"
                  id="residentialArea"
                />
              </Form.Item>

              <Form.Item
                name="industry"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select an industry you work in!",
                  },
                ]}
              >
                <Input
                  type="select"
                  options={
                    dropdowns?.industry?.map((item) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  defaultValue={userInfo?.industry}
                  onChange={(val) => {
                    setUserInfo((prev) => ({ ...prev, industry: val }));
                  }}
                  label="Industry"
                  id="industry"
                />
              </Form.Item>

              <Form.Item name="department" style={{ width: "100%" }}>
                <Input
                  type="select"
                  options={departments?.map((department) => ({
                    label: department.name,
                    value: department._id,
                  }))}
                  defaultValue={userInfo?.department?.deptName}
                  onChange={(val) => {
                    setUserInfo((prev) => ({ ...prev, department: val }));
                  }}
                  label="Department"
                  id="department"
                  loading={isPending}
                  error={error}
                />
              </Form.Item>
            </div>

            <div className="flex justify-end mt-4 mb-4">
              <div className="w-full md:w-1/4">
                <Button type="submit">
                  {loading ? <Loader type={"circle"} /> : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

const ProfileImgWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;

  img {
    width: 100%;
  }
`;

export default EditProfileTab;
