import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Text } from "../../components";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axiosPublic from "../../api/axios";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const ConnectGroup = () => {
  const axiosPrivate = useAxiosPrivate();
  const [selectedConnectGroup, setSelectedConnectGroup] = useState(null);
  const {
    isPending,
    error,
    data: groups,
  } = useQuery({
    queryKey: ["groups"],
    queryFn: async () => {
      const response = await axiosPublic("/api/users/get-connectGroups");
      return response.data;
    },
  });

  const handleSubmit = async () => {
    try {
      const response = await axiosPrivate.post("/api/users/join-connectGroup", {
        name: selectedConnectGroup.label,
      });

      if (response?.data) {
        toast.success(
          `You are now part of the ${selectedConnectGroup?.label} connect group!`
        );
        setSelectedConnectGroup(null);
      }
    } catch (err) {
      toast.error(err.response?.data?.message ?? "An error has occured");
    }
  };

  return (
    <Wrapper>
      <Text type="h1" className="pb-8">
        Select a connect group from the list below;
      </Text>
      <div className="w-full md:w-3/6 mb-8">
        <Input
          type="select"
          options={groups?.map((group) => ({
            label: group.name,
            value: group._id,
          }))}
          onChange={(_, valObj) => {
            console.log(valObj);
            setSelectedConnectGroup(() => valObj);
          }}
          showSearch
          label="Choose Connect Group"
          id="connect-group"
          loading={isPending}
          error={error}
          size="large"
        />
      </div>
      <div className="w-full md:w-3/6 mb-8">
        <Text type="p" className="mb-8">
          For detailed information about each connect group, click{" "}
          <Link to="/connect-groups">here</Link>.
        </Text>
      </div>
      <Button onClick={handleSubmit} size="large" width="fit-content">
        Join Group
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  background-color: transparent;
`;
