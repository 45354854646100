import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Text } from "../../components";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axiosPublic from "../../api/axios";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

export const DashboardVolunteer = () => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useAuth();
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const {
    isPending,
    error,
    data: departments,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: async () => {
      const response = await axiosPublic("/api/departments");
      return response.data;
    },
  });

  async function handleSubmit() {
    try {
      const response = await axiosPrivate.post(`/api/users/join-department`, {
        userId: auth?.user?._id,
        departmentId: selectedDepartment.value,
      });

      if (response.data) {
        toast.success("Thank you for volunteering your service");
      }
    } catch (err) {
      toast.error(err.response?.data?.message ?? "An error occured");
    }
  }

  return (
    <Wrapper>
      <Text type="h1" className="pb-8">
        Thank you for deciding to volunteer with us
      </Text>
      <div className="w-full md:w-3/6 mb-8">
        <Input
          type="select"
          options={departments?.map((department) => ({
            label: department.name,
            value: department._id,
          }))}
          onChange={(_, val) => {
            setSelectedDepartment(() => val);
          }}
          showSearch
          label="Choose Department"
          id="department"
          loading={isPending}
          error={error}
          size="large"
        />
      </div>
      <div className="w-full md:w-3/6 mb-8">
        <Text type="p" className="mb-8">
          For detailed information about each department, please visit the
          Volunteer page by clicking <Link to="/volunteer">here</Link>.
        </Text>
      </div>
      <Button onClick={handleSubmit} size="large" width="fit-content">
        Submit
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  background-color: transparent;
`;
