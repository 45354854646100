import React from "react";
import styled, { css } from "styled-components";
import { Typography } from "antd";

const styles = css`
  font-family: "Nunito Sans", sans-serif;
`;
const StyledH1 = styled.h1`
  ${styles};
  font-size: 27.621px;
  font-style: normal;
  font-weight: 400;
  color: #282828;
`;
// const StyledP = styled.p`
//   ${styles};
// `;

export const Text = ({ level, type, style, children, className, ...rest }) => {
  if (type === "h1")
    return <StyledH1 className={className}>{children}</StyledH1>;

  if (typeof level === "number" && level >= 1 && level <= 5) {
    return (
      <Typography.Title
        className={className}
        level={level}
        style={{ margin: 0, ...style }}
        {...rest}
      >
        {children}
      </Typography.Title>
    );
  }

  if (type === "p") {
    return (
      <Typography
        className={className}
        style={{ margin: 0, ...style }}
        {...rest}
      >
        <p>{children}</p>
      </Typography>
    );
  }

  if (typeof type === "string") {
    return (
      <Typography.Text
        className={className}
        type={type}
        style={{ margin: 0, ...style }}
        {...rest}
      >
        {children}
      </Typography.Text>
    );
  }
  // return <StyledP className={className}>{children}</StyledP>;
  return (
    <Typography.Text className={className} style={{ margin: 0 }} {...rest}>
      {children}
    </Typography.Text>
  );
};
