import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import giveImage from "../../../assets/images/give-img.png";
import { screen } from "../../../theme";
import { Button } from "../../atoms";

export const GivingIntro = ({ marginTop }) => {
  return (
    <GiveWrapper $marginTop={marginTop}>
      <div className="w-full">
        <img className="object-cover" src={giveImage} alt="" />
      </div>
      <div className="w-full">
        <GiveHeading>Online Giving</GiveHeading>
        <p className="give-text">
          And God is able to make all grace [every favor and earthly blessing]
          come in abundance to you, so that you may always [under all
          circumstances, regardless of the need] have complete sufficiency in
          everything [being completely self-sufficient in Him], and have an
          abundance for every good work and act of charity <br />
          <span style={{ fontWeight: 600 }}>2 Corinthians 9:8 (AMP)</span>
        </p>
        <div className="mt-4">
          <Link to="give">
            <Button
              width="fit-content"
              bgColor="transparent"
              color="#fff"
              border="1px solid white"
              padding="8px 34px"
            >
              Give
            </Button>
          </Link>
        </div>
      </div>
    </GiveWrapper>
  );
};

const HeadingStyles = css`
  color: #fff;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 38.8px;

  @media only screen and (${screen.md}) {
    font-size: 64px;
    line-height: 76.8px;
  }
`;
const GiveHeading = styled.h3`
  ${HeadingStyles};
  font-size: 1rem;
  text-align: left;
  line-height: 2rem !important;
  margin-bottom: 24px;
  font-size: 2rem;

  @media only screen and (${screen.md}) {
    width: 80%;
    line-height: 4rem !important;
  }
`;
const GiveWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  background: #603502;
  padding: 64px 24px;
  border-radius: 24px;
  color: white;
  margin-top: ${({ $marginTop }) => $marginTop || "64px"};

  .give-text {
    width: 80%;
  }

  @media only screen and (${screen.md}) {
    flex-direction: row;
    align-items: center;

    .give-text {
      font-size: 1.5rem;
    }
  }
`;
