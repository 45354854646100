import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../store/contexts/AuthProvider";
import useAxiosPrivate from "./useAxiosPrivate";

const useLogout = () => {
  const { setAuth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const response = await axiosPrivate.get("/api/logout");

      if (response.status === 204) {
        setAuth({});
        console.log("logout");
        navigate("/login", { replace: true });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
