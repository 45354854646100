import React, { useState } from "react";
import { Text } from "../../../../components";
import { Switch } from "antd";

export const PreferencesTab = () => {
  const [notificationSettings, setNotificationSettings] = useState({
    receiveWeeklyNotifications: true,
    receiveNewsLetters: true,
    syncEventsWithMyCalendar: false,
  });

  return (
    <div className="pt-6 pb-6">
      <Text level={5} style={{ color: "#5b5858" }}>
        Notificatiion
      </Text>

      <div className="flex flex-col gap-4 mt-8">
        <div className="flex gap-4">
          <Switch
            defaultChecked={notificationSettings.receiveWeeklyNotifications}
            onChange={(val) =>
              setNotificationSettings((prev) => ({
                ...prev,
                receiveWeeklyNotifications: val,
              }))
            }
          />
          <Text type="p">
            I want to receive weekly notifications for events, sermons and
            activities
          </Text>
        </div>

        <div className="flex gap-4">
          <Switch
            disabled
            defaultChecked={notificationSettings.syncEventsWithMyCalendar}
            onChange={(val) =>
              setNotificationSettings((prev) => ({
                ...prev,
                syncEventsWithMyCalendar: val,
              }))
            }
          />
          <Text type="p">
            I want to sync events with my calendar to get frequent reminders
          </Text>
        </div>

        <div className="flex gap-4">
          <Switch
            defaultChecked={notificationSettings.receiveNewsLetters}
            onChange={(val) =>
              setNotificationSettings((prev) => ({
                ...prev,
                receiveNewsLetters: val,
              }))
            }
          />
          <Text type="p">
            Opt-in or out of receiving news letters or promotional emails
          </Text>
        </div>
      </div>
    </div>
  );
};
