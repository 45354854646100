import React from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import axiosPublic from "../../api/axios";
import { Text } from "../../components";
import { Button, Skeleton } from "antd";
import { EventCard2 } from "../../components/molecules/EventCard2";

export const DashboardEvents = () => {
  const { isUpcomingPending, data: events } = useQuery({
    queryKey: ["upcoming-events"],
    queryFn: async () => {
      const response = await axiosPublic.get("/api/events/upcoming");
      return response.data;
    },
  });

  const { isRecentEventsPending, data: recentEvents } = useQuery({
    queryKey: ["recents-events"],
    queryFn: async () => {
      const response = await axiosPublic.get("/api/events/recent");
      return response.data;
    },
  });

  function openLink(url) {
    // Open the link in a new tab
    window.open(url, "_blank");
  }

  if (isUpcomingPending && isRecentEventsPending) return <Skeleton active />;
  return (
    <Wrapper>
      <Text level={3} style={{ color: "#5b5858", marginLeft: "2%" }}>
        Upcoming events
      </Text>
      <div className="flex gap-6 flex-col">
        {events?.upcomingEvents.map(
          ({
            image,
            eventName,
            date,
            time,
            volunteerLink,
            registrationLink,
          }) => (
            <EventItems className="flex items-center gap-12">
              <img
                style={{ marginLeft: "2%", borderRadius: 5 }}
                height={"20%"}
                width={"20%"}
                src={image}
                alt=""
              />

              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <Text level={4}>{eventName}</Text>
                  {/* <div>
                  <Button size="large" style={{ color: "#E36725" }}>
                    Add to calendar
                  </Button>
                </div> */}
                </div>
                <Text level={6} type="p">
                  {date.split("T")[0]} | {time}
                </Text>
                <Text level={5} type="p">
                  Upcoming Event
                </Text>
                <div className="flex gap-2">
                  {volunteerLink && (
                    <Button
                      onClick={() => openLink(volunteerLink)}
                      size="large"
                      style={{
                        color: "#fff",
                        background: "#E36725",
                        padding: "0 24px",
                      }}
                    >
                      Volunteer
                    </Button>
                  )}
                  {registrationLink && (
                    <Button
                      onClick={() => openLink(registrationLink)}
                      size="large"
                      style={{
                        padding: "0 24px",
                      }}
                    >
                      Register
                    </Button>
                  )}
                </div>
              </div>
            </EventItems>
          )
        )}
      </div>

      <div className="flex gap-4 flex-col">
        <Text level={3} style={{ color: "#5b5858" }}>
          Recent Events
        </Text>
        <div className="flex gap-4 overflow-x-auto">
          {recentEvents?.recentEvents
            ?.slice(0, 3)
            ?.map(({ image, eventName, date, time }) => (
              <EventCard2
                key={date.split("T")[0] + time}
                title={eventName}
                width="40%"
                height={"350px"}
                borderRadius={3}
                bgImage={image}
                date={date}
                time={time}
              />
            ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const EventItems = styled.div`
  padding-bottom: 24px;
`;
