import { useQuery } from "@tanstack/react-query";
import React from "react";
import axios from "../../api/axios";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Text } from "../../components/atoms";
import { AuthTemplate } from "../../templates/AuthTemplate";

export const AccountActivation = () => {
  let [searchParams] = useSearchParams();
  const { isPending, error } = useQuery({
    queryKey: ["activate-account"],
    queryFn: async () => {
      const response = await axios.post("/api/account-activation", {
        token: searchParams.get("token"),
      });
      return response.data;
    },
  });

  if (isPending) {
    return (
      <div className="h-[100vh]  flex flex-col justify-center items-center">
        Activating account...
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[100vh] flex flex-col justify-center items-center">
        <Text className="mb-4" type="h1">
          {error?.response?.data?.error ||
            "An error occurred, please try again"}
        </Text>
        <Link to="/register">
          <Button>Sign up</Button>
        </Link>
      </div>
    );
  }

  return (
    <AuthTemplate>
      <div className="flex mt-6 flex-col justify-center items-center">
        <Text className="mb-4" type="h1">
          Account Activation successful!
        </Text>
        <Link to="/Login">
          <Button>Login</Button>
        </Link>
      </div>
    </AuthTemplate>
  );
};
