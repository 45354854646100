import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import styled from "styled-components";
import { Button, Text } from "../../components";
// import profileSVG from "../../assets/svgs/side_nav/profile.svg";
// import eventSVG from "../../assets/svgs/side_nav/event.svg";
// import socialShareSVG from "../../assets/svgs/side_nav/share.svg";
// import logoutSVG from "../../assets/svgs/side_nav/logout.svg";
import settingsSVG from "../../assets/svgs/side_nav/settings.svg";
// import helpCenterSVG from "../../assets/svgs/side_nav/help-outline.svg";
import { screen } from "../../theme";
import useLogout from "../../hooks/useLogout";
import { useScreenSize } from "../../hooks/useScreenSize";

const { Sider } = Layout;

export const DashboardTemplate = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const [collapsed, setCollapsed] = useState(isMobile ? true : false);

  //TODO: Improve redering performance
  const items = [
    {
      // icon: profileSVG,
      title: "My profile",
      destination: "/dashboard",
    },
    // {
    // icon: businessSVG,
    //   title: "Notification",
    //   destination: "/notifications",
    // },
    {
      // icon: eventSVG,
      title: "Events",
      destination: "/dashboard-events",
    },
    {
      // icon: socialShareSVG,
      title: "Social media",
      destination: "/socials",
    },
    {
      // icon: socialShareSVG,
      title: "Give",
      destination: "/d/give",
    },
    {
      // icon: socialShareSVG,
      title: "Send Prayers",
      destination: "/d/send-prayer",
    },
    {
      // icon: socialShareSVG,
      title: "Join Department",
      destination: "/d/volunteer",
    },
    {
      // icon: socialShareSVG,
      title: "Connect Groups",
      destination: "d/connect-groups",
    },
    {
      //  icon:
      settingsSVG,
      title: "Settings",
      destination: "/settings",
    },
    {
      // icon: helpCenterSVG,
      title: "Support",
      destination: "https://tcnlekki.tawk.help",
    },
    {
      //icon: logoutSVG,
      title: "Logout",
      destination: "#",
    },
  ].map(({ title, icon, destination }) => ({
    key: String(title),
    icon: React.createElement("img", { src: icon }),
    label:
      title === "Logout" ? (
        <span
          style={{ display: "block", fontWeight: "bold" }}
          to="#"
          onClick={() => {
            logout();
          }}
        >
          {title}
        </span>
      ) : title === "Support" ? (
        <span
          style={{ display: "block", fontWeight: "bold" }}
          onClick={() => {
            isMobile && setCollapsed((prev) => !prev);
            window.open(destination, "_blank").focus();
          }}
        >
          {title}
        </span>
      ) : (
        <span
          style={{ display: "block", fontWeight: "bold" }}
          onClick={() => {
            isMobile && setCollapsed((prev) => !prev);
            navigate(destination);
          }}
        >
          {title}
        </span>
      ),
  }));

  return (
    <Layout style={{ height: "100vh" }}>
      <SiderWrapper
        style={{
          background: "#FCEFE7",
          padding: "0",
          position: "fixed",
          top: 0,
          bottom: 0,
          // display: "flex",
          // justifyContent: "space-between",
          // flexDirection: "column",
        }}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsed((prev) => !prev);
        }}
        collapsed={collapsed}
      >
        <SideNav>
          <Link to="/">
            <img style={{ width: "120px" }} src={logo} alt="" />
          </Link>
        </SideNav>
        <div id="nav-items">
          <Menu
            style={{
              background: "transparent",
              color: "#151515",
              padding: "24px 0",
              margin: 0,
              border: "none",
            }}
            mode="inline"
            defaultSelectedKeys={["4"]}
            items={items.slice(0, -3)}
          />
          <Menu
            style={{
              background: "transparent",
              color: "#151515",
              padding: "24px 0",
              margin: 0,
              border: "none",
            }}
            mode="inline"
            items={items.slice(-3)}
          />
        </div>
      </SiderWrapper>
      <Layout style={{ height: "100vh", overflow: "auto" }}>
        <Content>
          <nav className="flex justify-between items-center px-4">
            <Text level={3}>My Dashboard</Text>
            <Link to="/d/join-core-team">
              <Button
                style={{ fontWeight: "bold" }}
                padding="10px 24px"
                width="fit-content"
              >
                Join Core Team
              </Button>
            </Link>
          </nav>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

const SideNav = styled.nav`
  background-color: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  background: #fafafa;
  nav {
    height: 80px;
    background: #fff;
  }
`;
const SiderWrapper = styled(Sider)`
  z-index: 1;

  #nav-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92%;
  }

  :where(.css-dev-only-do-not-override-1qhpsh8).ant-menu-light
    .ant-menu-item-selected,
  :where(.css-dev-only-do-not-override-1qhpsh8).ant-menu-light
    > .ant-menu
    .ant-menu-item-selected {
    background-color: #e36725;
    color: white;
    border-radius: 0;
  }

  @media only screen and (${screen.lg}) {
    position: static !important;
    height: inherit;
  }
`;
