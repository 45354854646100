import React, { useState } from "react";
import { Container } from "../../atoms/Container";
import styled from "styled-components";
import profile from "../../../assets/images/defaultDP.jpeg";

import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { Text } from "../../../components";
import { Button } from "../../atoms";
import { Modal } from "antd";
import { screen } from "../../../theme";
import { Icons } from "../../atoms/Icons";
import { MobileMenu } from "../../organisms";
import useAuth from "../../../hooks/useAuth";
import useLogout from "../../../hooks/useLogout";

const Wrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px;

  .nav-links {
    display: none;
    gap: 23px;
    align-items: center;

    a {
      white-space: nowrap;
    }
  }

  .mobile-menu-btn {
    display: flex;
    padding: 8px 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    border: 1px solid #e36725;
    color: #e36725;
    font-weight: 600;
    cursor: pointer;
  }

  @media only screen and (${screen.md}) {
    padding: 7px 0;

    .nav-links {
      display: flex;
    }

    .mobile-menu-btn {
      display: none;
    }
  }
`;

const ProfileModal = styled(Modal)`
  .ant-modal-content {
    max-width: 250px;
    margin: -44px 33% 0 auto;
    margin-right: -80%;
  }
`;

export const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); // State for profile modal
  const auth = useAuth();
  // const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const logout = useLogout();

  // const handleLogout = async () => {
  //   try {
  //     const response = await axiosPublic.get("/api/logout");

  //     if (response.status === 204) {
  //       setAuth({});
  //       toast.success("Logged out!");
  //     }
  //   } catch (err) {
  //     toast.error(err.response.data.error);
  //   }
  // };

  const handleDashboardClick = () => {
    setShowProfileModal(false); // Close the modal first
    navigate(`/dashboard`); // Pass userId as query parameter
  };

  return (
    <Wrapper>
      <Link to="/">
        <LogoImg className="mb-2" src={logo} alt="logo" />
      </Link>

      <div className="nav-links">
        <Link to="/">Home</Link>
        <Link to="/about">About Us</Link>
        <Link to="/events">Events</Link>
        <Link to="/volunteer">Volunteer</Link>
        <Link to="/give">Give</Link>
        {auth.user ? (
          <Button onClick={() => setShowProfileModal(true)} padding="8px 20px">
            {" "}
            {/* Open profile modal */}
            Profile
          </Button>
        ) : (
          <Link to="/register">
            <Button padding="8px 20px">Register</Button>
          </Link>
        )}
      </div>

      <button
        className="mobile-menu-btn"
        onClick={() => setShowMobileMenu((prev) => !prev)}
      >
        MENU
        <Icons type="hamburger" />
      </button>

      {showMobileMenu ? <MobileMenu setMenuOpen={setShowMobileMenu} /> : null}

      {/* Profile Modal */}
      <ProfileModal
        // title="Profile"
        visible={showProfileModal} // Use `visible` instead of `open`
        onCancel={() => setShowProfileModal(false)}
        footer={null}
      >
        {/* Content of profile modal */}
        {/* You can place the logout button and other profile details here */}
        <div>
          <div
            onClick={handleDashboardClick}
            style={{
              marginTop: 20,
              paddingBottom: 4,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-around",
              gap: 2,
              width: "70%",
            }}
          >
            <img
              height={5}
              width={25}
              style={{ borderRadius: "50%" }}
              src={profile}
              alt="profile"
            />
            <Text level={5}>My Dashboard</Text>
          </div>
          <hr style={{ color: "#999999", margin: "4px", height: "5px" }} />
          <Button onClick={logout} padding="8px 20px">
            Logout
          </Button>
          {/* Add other profile information here */}
        </div>
      </ProfileModal>
    </Wrapper>
  );
};

const LogoImg = styled.img`
  width: 120px;
`;
