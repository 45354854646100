import React from "react";
import styled from "styled-components";
import profile from "../../assets/images/defaultDP.jpeg";
import { Text } from "../../components";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Icons } from "../../components/atoms/Icons";
import { Link } from "react-router-dom";

export const Dashboard = () => {
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const {
    isFetching: isUserDetailsFetching,
    error: userDetailsError,
    data: userDetails,
  } = useQuery({
    queryKey: "user-details",
    queryFn: async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/users/get-user-details/${auth?.user?._id}`
        );
        console.log("response", response);
        return response.data;
      } catch (error) {
        throw new Error(`Failed to fetch user details: ${error.message}`);
      }
    },
    enabled: !!auth?.user?._id,
    refetchOnWindowFocus: false,
  });

  if (isUserDetailsFetching) {
    return (
      <div className="h-[100vh]  flex flex-col justify-center items-center">
        Fetching Details
      </div>
    );
  }

  if (userDetailsError) {
    return (
      <div className="h-[100vh] flex flex-col justify-center items-center">
        <Text className="mb-4" type="h1">
          {userDetailsError?.response?.data?.error ||
            "An error occurred, please try again"}
        </Text>
      </div>
    );
  }

  return (
    <Wrapper>
      <Section className="flex flex-col md:flex-row gap-12 items-center">
        <img
          style={{ width: "90px", height: "90px", borderRadius: "50%" }}
          src={profile}
          alt="profile"
        />
        <div className="w-full details-wrapper flex justify-between">
          <div>
            <Text
              level={3}
            >{`${userDetails?.surName} ${userDetails?.firstName}`}</Text>
            <Text className="mt-2" level={5}>
              {userDetails?.industry}
            </Text>
            <Text className="mt-2" type="p">
              {userDetails?.email}
            </Text>
          </div>
          <EditBtn to="/settings">
            Edit <Icons type="edit" />
          </EditBtn>
        </div>
      </Section>
      <Section className="flex flex-col gap-12">
        <div className="w-full flex items-center justify-between">
          <Text level={4}>Personal Information</Text>
        </div>
        <InfoGrid>
          <div>
            <Text type="p">First name</Text>
            <Text level={5}>{userDetails?.firstName}</Text>
          </div>
          <div>
            <Text type="p">Surname</Text>
            <Text level={5}>{userDetails?.surName}</Text>
          </div>
          <div>
            <Text type="p">Gender</Text>
            <Text level={5}>{userDetails?.gender}</Text>
          </div>
          <div>
            <Text type="p">Email address</Text>
            <Text level={5}>{userDetails?.email}</Text>
          </div>
          <div>
            <Text type="p">Phone number</Text>
            <Text level={5}>{userDetails?.phoneNumber}</Text>
          </div>
          <div>
            <Text type="p">Birthday</Text>
            <Text level={5}>{userDetails?.birthDay}</Text>
          </div>
        </InfoGrid>
      </Section>
      <Section className="flex flex-col gap-12">
        <div className="w-full flex items-center justify-between">
          <Text level={4}>Other Information</Text>
        </div>
        <InfoGrid>
          <div>
            <Text type="p">Department in church</Text>
            <Text level={5}>
              {userDetails?.department?.deptName ?? "No Dept Yet"}
            </Text>
          </div>
        </InfoGrid>
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Section = styled.section`
  border-radius: 5px;
  border: 1px solid #cac6c6;
  padding: 24px;

  .details-wrapper {
    height: fit-content;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 36px;
`;

const EditBtn = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  height: fit-content;
  border-radius: 1rem;
  border: 1px solid #7d7b7b;
`;
