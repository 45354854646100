import React, { useState } from "react";
import { PageTemplate } from "../../templates/PageTemplate";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import background from "../../assets/images/bg.jpg";
import { Container } from "../../components/atoms/Container";

// import Baptismal from "../../assets/images/units/baptismal.png";
// import camera from "../../assets/images/units/camera.png";
// import childrenChurch from "../../assets/images/units/children-church.png";
// import content from "../../assets/images/units/content.png";
// import info_desk from "../../assets/images/units/info_desk.png";
// import levites from "../../assets/images/units/levites.png";
// import medical_team from "../../assets/images/units/medical_team.png";
// import pastorial from "../../assets/images/units/pastorial.png";
// import pos from "../../assets/images/units/pos.png";
// import sunday_school from "../../assets/images/units/sunday_school.png";
// import technical_sound from "../../assets/images/units/technical_sound.png";
// import teens from "../../assets/images/units/teens.png";
// import traffic from "../../assets/images/units/traffic.png";
// import ushering from "../../assets/images/units/ushering.png";
// import venue_management from "../../assets/images/units/venue_management.png";
// import w2m from "../../assets/images/units/w2m.png";
import { screen } from "../../theme";
import { VolunteerModal } from "../../components/organisms/VolunteerModal";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "antd";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

export const Volunteer = () => {
  const [openVolunteerModal, setOpenVolunteerModal] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  // const [payload, setPayload] = useState({});
  const {
    isPending,
    error,
    data: departments,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: async () => {
      const response = await axiosPrivate("/api/departments");
      return response.data;
    },
  });

  async function joinDepartment(userID, departmentID) {
    if (!auth?.user) {
      setOpenVolunteerModal(true);
      return;
    }

    try {
      const response = await axiosPrivate.post(`/api/users/join-department`, {
        userID,
        departmentID,
      });
      if (response.data) {
        toast.success("Thank you for volunteering your service");
      }
    } catch (err) {
      toast.error(err.response?.data?.message ?? "An error occured");
    }
  }

  if (error) return "An error occured while fetching departments";

  return (
    <PageTemplate>
      <Hero>
        <div className="w-full md:w-2/4">
          <HeroHeading>Volunteer With Us!</HeroHeading>
          <HeroSubText>
            The following are the various departments and units in The Covenant
            Temple, Lekki. New members are encouraged to join at least one
            department/unit by filling a membership form online or in Church.
          </HeroSubText>
        </div>
      </Hero>

      {isPending ? (
        <Skeleton active />
      ) : (
        <Departments>
          {departments.map((department) => {
            const userHasDepartment = auth?.user?.department?.id;
            return (
              <GetInvolved key={department._id}>
                <Container className="flex flex-col md:flex-row gap-12 items-center">
                  <div className="flex flex-col gap-4 w-full md:w-2/4">
                    <p className="invite-text">{department.name}</p>
                    <p className="invite-sub-text">{department.description}</p>
                    <div className="w-full md:w-1/5">
                      <Button
                        width="fit-content"
                        onClick={
                          userHasDepartment
                            ? () => navigate("/settings")
                            : () =>
                                joinDepartment(auth?.user?._id, department._id)
                        }
                        padding="8px 20px"
                      >
                        {userHasDepartment ? "Update department" : "Join us"}
                      </Button>
                    </div>
                  </div>
                  <div className="w-full md:w-2/4">
                    <img
                      className="event-image"
                      src={department.imgURL}
                      alt="event"
                    />
                  </div>
                </Container>
              </GetInvolved>
            );
          })}
        </Departments>
      )}

      {openVolunteerModal && (
        <VolunteerModal
          open={openVolunteerModal}
          setOpen={setOpenVolunteerModal}
        />
      )}
    </PageTemplate>
  );
};

const HeadingStyles = css`
  color: #fff;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 38.8px;

  @media only screen and (${screen.md}) {
    font-size: 64px;
    line-height: 76.8px;
  }
`;
const SubHeadingStyles = css`
  color: #fff;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;

  @media only screen and (${screen.md}) {
    font-size: 24px;
    line-height: 36px;
  }
`;
const GetInvolved = styled.div`
  display: flex;
  padding: 98px 24px;
  flex-direction: column;

  p.invite-text {
    color: #000;
    ont-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  p.invite-sub-text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media only screen and (${screen.md}) {
    padding: 98px 60px;
    flex-direction: row;

    .invite-img {
      height: auto;
    }

    p.invite-text {
      font-size: 48px;
      line-height: 120%;
    }
    p.invite-sub-text {
      font-size: 18px;
      line-height: 150%;
    }
  }
`;
const Hero = styled.section`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
`;
const HeroHeading = styled.h1`
  ${HeadingStyles};
`;
const HeroSubText = styled.p`
  ${SubHeadingStyles};
`;
const Departments = styled.section`
  > div:nth-child(odd) {
    background: #fdf5d9;
  }

  @media only screen and (${screen.md}) {
    > div:nth-child(even) > div {
      flex-direction: row-reverse;
    }
  }
`;
