import React, { useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { Button, Input, Text } from "../../components/atoms";
import { AuthTemplate } from "../../templates/AuthTemplate";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`/api/forgot-password`, {
        email,
      });
      if (response.data) {
        toast.success("Reset password email sent");
        setEmail("");
      }
    } catch (err) {
      toast.error("An error occured while sending reset email");
    }
  };

  return (
    <AuthTemplate>
      <div className="flex flex-col mt-8 mb-3">
        <Text type="h1">Forgot Password</Text>
        <Text>Enter your email and we will Send you a reset link</Text>
      </div>

      <Input
        type="email"
        placeholder="enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="E-mail or phone number"
        id="email"
      />

      <div className="mt-4">
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </AuthTemplate>
  );
};
