import React, { useState } from "react";
import { Container } from "../../atoms/Container";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { Button, Input, Text } from "../../atoms";
import { screen } from "../../../theme";
import { Form } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const Wrapper = styled(Container)``;
const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 20px 0;
  flex-wrap: wrap;

  div.quick-links {
    display: flex;
    gap: 23px;
    flex-direction: column;
  }

  @media only screen and (${screen.lg}) {
    padding: 80px 0;

    div {
      margin-bottom: 2rem;
    }

    flex-wrap: no-wrap;
    div {
      width: fit-content;
    }
  }
`;

const BottomSection = styled.section`
  border-top: 1px solid black;
  padding: 24px 0 80px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  & nav {
    display: flex;
    gap: 34px;
    justify-content: center;
    flex-wrap: wrap;

    a {
      white-space: nowrap;
    }
  }

  @media only screen and (${screen.lg}) {
    flex-direction: row;

    & nav {
      justify-content: space-between;
    }
  }
`;

export const Footer = () => {
  const [userEmail, setUserEmail] = useState({});
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/subscribe-newsletter", {
        email: userEmail,
      });

      if (response.data) {
        form.resetFields();
        toast.success("You have been added to our subscription list!");
      }
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };

  return (
    <Wrapper>
      <TopSection>
        <div className="flex flex-col gap-3 mb-10 lg:mb-0">
          <Link to="/">
            <LogoImg className="mb-2" src={logo} alt="logo" />
          </Link>
          <Text type="p">
            Join our newsletter to stay up to date on features and releases.
          </Text>

          <Form
            form={form}
            name="subscribe"
            onFinish={handleSubmit}
            scrollToFirstError
          >
            <div className="w-full flex flex-wrap md:flex-nowrap gap-2 ">
              <div className="w-full">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    border="1px solid black"
                    type="email"
                    placeholder="Your email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <Button
                  bgColor="transparent"
                  color="#000"
                  border="1px solid black"
                  padding="8px 20px"
                  width="fit-content"
                >
                  Subscribe
                </Button>
              </div>
            </div>
          </Form>

          <div className="w-full md:w-4/5">
            <Text type="p">
              By subscribing you agree to with our Privacy Policy and provide
              consent to receive updates from our company.
            </Text>
          </div>
        </div>

        <div className="quick-links mb-10 lg:mb-0">
          <Text type="h3" className="font-bold">
            Latest News
          </Text>
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/events">Events</Link>
          <Link to="/volunteer">Volunteer</Link>
        </div>

        <div className="quick-links lg:mb-0">
          <Text type="h3" className="font-bold">
            Latest News
          </Text>
          <a href="https://www.instagram.com/tcnlekki/">Instagram</a>
          {/* <a href="https://www.instagram.com/tcnlekki/">Instagram</a>
          <a href="https://www.instagram.com/tcnlekki/">Twitter</a> */}
        </div>
      </TopSection>

      <BottomSection>
        <Text className="mb-8" type="p">
          &copy; 2024 TCN LEKKI. All rights reserved.
        </Text>
        <nav>
          <Link
            target="_blank"
            to="https://www.insightsforliving.org/privacypolicy/"
          >
            Privacy Policy & Terms of Use
          </Link>
          {/* <Link to="#">Terms of service</Link> */}
          {/* <Link to="#">Cookies Settings</Link> */}
        </nav>
      </BottomSection>
    </Wrapper>
  );
};

const LogoImg = styled.img`
  width: 120px;
`;
