import React from "react";
import styled from "styled-components";
import { Text } from "../../components";
import coreTeamBarCode from "../../assets/images/core-team-bar-code.png";

export const JoinCoreTeam = () => {
  return (
    <Wrapper>
      <Text type="h1" className="pb-8">
        Welcome to TCN Lekki Core Team!
      </Text>

      <div className="w-full md:w-3/6 mb-8">
        <Text type="p" className="mb-8">
          The Core Team of TCN Lekki consists of church members who come
          together to pray for the congregation. We are a church that strongly
          believes in the power of prayer and the importance of praying together
          for one another. Use this QR code or WhatsApp link to join the TCN
          Lekki Core Team today!
        </Text>
      </div>

      <div className="w-full mb-8 flex flex-col sm:flex-row gap-16 items-center">
        <JoinOptionWrapper>
          <img src={coreTeamBarCode} alt="Core team bar code" />
        </JoinOptionWrapper>
        <span>or use the link</span>
        <a
          href="https://chat.whatsapp.com/Gp22evv0CKc2IX3yxJr47c"
          target="_blank"
          rel="noreferrer"
        >
          <JoinOptionWrapper $bgcolor="#603502">
            <p className="p-8">Click here</p>
          </JoinOptionWrapper>
        </a>
      </div>
    </Wrapper>
  );
};

const JoinOptionWrapper = styled.div`
  width: 250px;
  height: 250px;
  border: 1px solid #a7a7a7;
  background: ${({ $bgcolor }) => $bgcolor};
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;

  p {
    white-space: normal;
    width: fit-content;
    text-wrap: pretty;
  }

  img {
    width: 100%;
  }
`;
const Wrapper = styled.div`
  padding: 2rem;
  background-color: transparent;
`;
