import { format } from "date-fns";
import styled from "styled-components";

const CardWrapper = styled.div`
  border-radius: 8px;
  background: #fff;

  .event-image {
    width: 100%;
    border-radius: 8px;
  }
  .title {
    padding: 4px 8px;
    background: #f4f4f4;
  }
  .card-body {
    display: flex;
    padding: 34px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .time {
    color: #e36725;
  }
`;

export const EventCard3 = ({
  className = "w-full md:w-1/4",
  img,
  name,
  date,
  time,
  regLink,
  eventType,
}) => {
  return (
    <CardWrapper className={className}>
      <a href={regLink ?? ""} target="_blank" rel="noreferrer">
        <img className="event-image" src={img ?? ""} alt="event" />
        <div className="card-body">
          <div className="flex items-center gap-3">
            <p className="title">{eventType ?? "Event"}</p>
            <p className="date">{format(new Date(date), "dd MMM, yyyy")}</p>
          </div>
          <p className="description">{name ?? ""}</p>
          <p className="time">{time}</p>
        </div>
      </a>
    </CardWrapper>
  );
};
