import React from "react";
import styled from "styled-components";

export const BankDetailsCard = ({
  logo,
  bankName,
  accountName,
  accountNumber,
  textAlign,
  alignItems,
}) => {
  return (
    <Wrapper $textAlign={textAlign} $alignItems={alignItems}>
      <img src={logo} alt="Gtbank" />
      <h3>{bankName}</h3>
      <p className="account-name">{accountName}</p>
      <p>{accountNumber}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems || "center"};
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  max-width: 396px;
  font-weight: 600;
  // padding: 1rem;
  // border: 1px solid black;

  > img {
    width: 70px;
    height: 70px;
    margin-bottom: 12px;
  }

  .account-name {
    max-width: 200px;
    padding: 12px 0;
  }
`;
