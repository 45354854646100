import React from "react";
import styled from "styled-components";
import { Icons } from "../../atoms/Icons";
import { Link } from "react-router-dom";
import { screen } from "../../../theme";

const Wrapper = styled.div`
  padding: 24px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background: #fff;
  z-index: 99999;

  @media only screen and (${screen.md}) {
    margin: 0;
    display: none;
  }
`;
const HeadSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 24px 0;
`;
const MenuBody = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const MenuItems = styled(Link)`
  color: #535763;
  font-family: "Nunito Sans", sans-serif;
  font-size: 22.519px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.778px; /* 150% */
  letter-spacing: -0.225px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const MobileMenu = ({ setMenuOpen }) => {
  return (
    <Wrapper>
      <HeadSection>
        <Icons type="mobile-menu-icon" />
        <Icons type="close" onClick={() => setMenuOpen(false)} />
      </HeadSection>
      <MenuBody>
        <MenuItems to="/">
          <Icons type="home" /> Home
        </MenuItems>
        <MenuItems to="/about">
          <Icons type="globe" /> About Us
        </MenuItems>
        <MenuItems to="/events">
          <Icons type="event" /> Events
        </MenuItems>
        <MenuItems to="/volunteer">
          <Icons type="team" /> Volunteer
        </MenuItems>
        <MenuItems to="/give">
          <Icons type="team" /> Give
        </MenuItems>
        <MenuItems to="/dashboard">
          <Icons type="profile" /> Profile
        </MenuItems>
      </MenuBody>
    </Wrapper>
  );
};
