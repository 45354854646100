import { Modal } from "antd";
import React from "react";
import { Text } from "../../atoms";
export const VolunteerModal = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      title="Thank you for deciding to volunteer in church"
      footer={null}
      onCancel={() => setOpen(false)}
    >
      <Text type="p">
        Only members can join a department. Kindly{" "}
        <a href="/register">register</a> as a member or{" "}
        <a href="/login">login</a> if you already have an account.
      </Text>
    </Modal>
  );
};
