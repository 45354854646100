import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import AuthContext from "../../store/contexts/AuthProvider";
import { Input } from "../../components/atoms/Input";
import { Button } from "../../components/atoms/Button";
import { Text } from "../../components/atoms";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { Loader } from "../../components/atoms/Loader";

export const Login = () => {
  const auth = useAuth();
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      if (!email || !password) {
        toast.error("Please enter email and password.");
        return;
      }
      setLoading(true);
      const response = await axios.post("api/signin", {
        email,
        password,
      });
      if (response.data) {
        setLoading(false);
        toast.success("Login successful");
        setAuth(response.data);
        navigate(from || "/dashboard", { replace: true });
      }
    } catch (err) {
      setLoading(false);
      if (err?.response.status === 400 || err?.response.status === 401) {
        toast.error("Invalid credentials. Check email/password!");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    if (auth?.user) {
      navigate(from, { replace: true });
    }
  }, [auth, from, navigate]);

  return (
    <AuthTemplate>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col items-center mb-3">
          <Text type="h1">Login</Text>
          <Text>Enter details to create your account</Text>
        </div>

        <div className="flex flex-col gap-4">
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            id="email"
          />
          <Input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            id="password"
          />

          <Text className="text-right">
            <Link to="/forgot-password">Forgot password?</Link>
          </Text>

          <div>
            <Button type="submit">
              {loading ? <Loader type={"circle"} /> : "Submit"}
            </Button>

            <Text className="text-center pt-4">
              <Link to="/register">Don’t have an account? Sign up</Link>
            </Text>
          </div>
        </div>
      </form>
    </AuthTemplate>
  );
};
